<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="requestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="startDateValue"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="endDateValue"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="requestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #requestDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Search:</mat-label>
      <input [(ngModel)]="search" #searchInput matInput />
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Downloads">
    <app-download-csv-button
      [csvFilter]="filterParams"
      buttonText="Logs
Reports"
    ></app-download-csv-button>
  </app-fieldset>

  <div class="row">
    <app-card [cardTitle]="''">
      <table
        mat-table
        [dataSource]="dataSource"
        class="transactions-table"
        [loader]="isLoading"
      >
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Created At</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            {{ element.createdAt | date : "yyyy-MM-dd 'at' HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentId">
          <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
          <td mat-cell *matCellDef="let element">
            {{ element.paymentId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef>Message</th>
          <td mat-cell *matCellDef="let element">
            {{ element.message }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
  <div style="max-width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
