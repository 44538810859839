import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @ViewChild('modalOverlay') modalOverlay!: ElementRef;

  @Input() openModal = false;
  @Input() minWidth: string = 'auto';
  @Input() title: string = '';
  @Input() showCloseButton: boolean = true;
  @Output() closeModalRequest = new EventEmitter<void>();
  public theme = environment.theme
  
  ngAfterViewInit() {
    if (this.openModal) {
      setTimeout(() => {
        this.modalOverlay.nativeElement.focus();
      });
    }
  }

  public close() {
    this.closeModalRequest.emit();
  }
}
