import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel } from '../models/currency.model';

@Pipe({
  name: 'formatCurrency',
  pure: false,
})
export class FormatCurrencyPipe implements PipeTransform {
  private cacheAPI = sessionStorage;
  transform(
    value: number,
    currency: string = 'USD',
    showCurrencySymbol: boolean = true,
    convert: boolean = true
  ): string {
    const currencySymbol = JSON.parse(
      this.cacheAPI.getItem('_cache_assets') || '[]'
    ).filter((item: CurrencyModel) => item.asset === currency)[0]?.symbol;

    const currencyRate = convert
      ? JSON.parse(this.cacheAPI.getItem('_cache_values') || '[]').filter(
          (item: CurrencyModel) => item.asset === currency
        )[0]?.amount
      : 1;

    if (value) {
      const rawValue = (value * currencyRate).toFixed(2).toString();
      let formattedValue = rawValue.split('.')[0];

      let wholePart = formattedValue.replace(/^0+/, '');
      const decimalPart = rawValue.split('.')[1];

      if (!wholePart.length) {
        wholePart = '0';
      }

      formattedValue =
        wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimalPart;

      if (showCurrencySymbol) {
        return `${
          currencySymbol === '$' && currency !== 'USD'
            ? currency + ' ' + currencySymbol
            : currencySymbol
        } ${formattedValue}`;
      }
      return `${formattedValue}`;
    }
    return '';
  }
}
