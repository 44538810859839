export function capitalizeFirstLetter(str: string): string {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
}


export function getViewValue(separator: string): string {
  switch (separator) {
    case 'DOT':
      return '. (dot)';
    case 'SEMICOLON':
      return '; (semicolon)';
    case 'COLON':
      return ': (colon)';
    case 'PIPE':
      return '| (pipe)';
    case 'SLASH':
      return '/ (slash)';
    case 'COMMA':
      return ', (comma)';
    default:
      return separator;
  }
}
