import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel } from '../models/currency.model';

@Pipe({
  name: 'convertCurrency',
  pure: false,
})
export class ConvertCurrencyPipe implements PipeTransform {
  private cacheAPI = sessionStorage;

  transform(
    value: number,
    fromCurrency: string,
    toCurrency: string
  ): string | null {
    const fromCurrencyRate = this.getCurrencyRate(fromCurrency);
    const toCurrencyRate = this.getCurrencyRate(toCurrency);

    if (value && fromCurrencyRate && toCurrencyRate) {
      const convertedValue = (value * fromCurrencyRate) / toCurrencyRate;
      return convertedValue.toFixed(2);
    }
    return null;
  }

  private getCurrencyRate(currency: string): number | null {
    const currencyData = JSON.parse(
      this.cacheAPI.getItem('_cache_values') || '[]'
    ).filter((item: CurrencyModel) => item.asset === currency)[0];

    return currencyData ? currencyData.amount : null;
  }
}
