import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private baseUrl = `${environment.apiURL}/wallet`;

  constructor(private http: HttpClient) {}

public getPayoutsBalance(){
  return this.http.get<any>(`${this.baseUrl}/balance`);
}

}
