import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { capitalizeFirstLetter } from '../../../assets/common/functions/functions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public menuName: string = '';
  public subMenuName: string = '';
  public appName: string = '';
  public url: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateRouteData();
      }
    });

    this.updateRouteData();

    this.appName = environment.theme === 'skin-letpay' ? 'Letpay' : 'Epag';
  }

  private updateRouteData(): void {
    this.url = new URL(window.location.href).pathname;
    this.menuName = capitalizeFirstLetter(this.url.split('/')[1]);
    this.subMenuName = capitalizeFirstLetter(this.url.split('/')[2]);
  }
}
