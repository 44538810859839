import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartConfiguration,
  ChartData,
  ChartDataset,
  ChartType,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { environment } from '../../../environments/environment';

export interface TotalRevenue {
  amount: number;
  count: number;
  fluctuation: number;
  transactionFluctuation: number;
  aspFluctuation: number;
  avgticket: number;
  previousValues: TotalRevenue | null;
}

export interface Indices {
  count: number;
  amount: number;
  day: string;
  month: string;
  year: string;
}

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}

  @ViewChild(BaseChartDirective, { static: true }) chart:
    | BaseChartDirective
    | undefined;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 2.5,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {},
      y2: {},
    },
    elements: {
      line: {
        tension: 0.1,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: 'Last 90 Days Revenues',
        align: 'start',
        font: {
          size: 22,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: this.tooltipTitleCallback.bind(this),
        },
      },
    },
  };

  public barChartType: ChartType = 'bar';
  public cacheAPI = sessionStorage;
  public currency = 'USD';
  public isLoading: boolean = false;

  public chartDataSet: ChartData<'bar' | 'line'> = {
    labels: [],
    datasets: [],
  };

  public totalRevenue: TotalRevenue = {
    amount: 0,
    count: 0,
    fluctuation: 0,
    transactionFluctuation: 0,
    aspFluctuation: 0,
    avgticket: 0,
    previousValues: null,
  };
  public originalDates: string[] = [];

  private barChartData: Array<number> = [];
  private lineChartData: Array<number> = [];
  private colorLineChart = {
    color1: '',
    color2: '',
  };
  private colorBarChart = {
    color1: '',
    color2: '',
  };

  ngOnInit() {
    this.isLoading = true;
    this.currency = JSON.parse(
      this.cacheAPI.getItem('selectedCurrency') || '"USD"'
    );
    this.dashboardService
      .getIndices(90, 'none')
      .subscribe(({ response, totals }) => {
        this.formatRevenue(totals);

        const last90Days = this.generateLast90Days();
        const sortedResponse = response.sort((a: Indices, b: Indices) => {
          const aDate = new Date(
            Number(a.year),
            Number(a.month) - 1,
            Number(a.day)
          );
          const bDate = new Date(
            Number(b.year),
            Number(b.month) - 1,
            Number(b.day)
          );
          return aDate.getTime() - bDate.getTime();
        });

        const mergedData: Indices[] = [];

        last90Days.forEach((date) => {
          const dataForDate = sortedResponse.find(
            (item: any) => `${item.year}-${item.month}-${item.day}` === date
          );
          if (dataForDate) {
            mergedData.push(dataForDate);
          } else {
            const dateParts = date.split('-');
            mergedData.push({
              year: String(dateParts[0]),
              month: String(dateParts[1]),
              day: String(dateParts[2]),
              count: 0,
              amount: 0,
            });
          }
        });
        const dates: Array<string> = [];
        mergedData.forEach((value: Indices) => {
          this.barChartData.push(value.count);
          this.lineChartData.push(value.amount);

          let formattedDate = new Date(
            Number(value.year),
            Number(value.month) - 1,
            Number(value.day) + 1
          )
            .toISOString()
            .split('T')[0];
          dates.push(formattedDate);
        });
        const minBarValue = Math.min(...this.barChartData);
        if (!this.barChartOptions?.scales) {
          this.barChartOptions = { ...this.barChartOptions, scales: {} };
        }
        const maxLineValue = Math.trunc(1.1 * Math.max(...this.lineChartData));
        if (this.barChartOptions?.scales) {
          this.barChartOptions.scales['y'] = {
            type: 'linear',
            display: true,
            position: 'left',
            min: minBarValue / 2,
          };
          this.barChartOptions.scales['y2'] = {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              text: 'Amount',
              display: true,
            },
            max: maxLineValue,
          };
        }

        this.originalDates = [...dates];
        const labels = this.formatLabels(dates, dates.length);
        this.chartDataSet.labels = labels;
        this.chart?.update();
      });
  }

  ngAfterViewInit() {
    this.colorLineChart =
      environment.theme === 'skin-letpay'
        ? {
            color1: '#E45859',
            color2: '#F28A3C',
          }
        : {
            color1: '#295DA0',
            color2: '#1B98E0',
          };

    this.colorBarChart =
      environment.theme === 'skin-letpay'
        ? {
            color1: '#E45859',
            color2: '#F28A3C',
          }
        : {
            color1: '#5CEBFF',
            color2: '#0075FF',
          };

    if (this.chart && this.chart.chart && this.chart.chart.canvas) {
      const ctx = this.chart.chart.ctx;
      if (ctx) {
        const lineDataSet: ChartDataset<'line'> = {
          data: this.lineChartData,
          label: 'Payments',
          type: 'line' as const,
          fill: false,
          borderColor: this.getGradientColor(
            ctx,
            this.colorLineChart.color1,
            this.colorLineChart.color2
          ),
          pointBackgroundColor: '#fff',
          pointBorderColor:
            environment.apiURL === 'skin-letpay' ? '#E45859' : '#295DA0',
          spanGaps: true,
          order: 1,
          yAxisID: 'y2',
        };

        const barDataSet: ChartDataset<'bar'> = {
          data: this.barChartData,
          label: 'Number of orders',
          backgroundColor: this.getGradientColor(
            ctx,
            this.colorBarChart.color1,
            this.colorBarChart.color2
          ),
          order: 2,
          yAxisID: 'y',
        };

        this.chartDataSet.datasets = [barDataSet, lineDataSet];
        this.chart?.update();
      }
    }
  }

  private formatRevenue(totals: TotalRevenue) {
    const { amount, count, avgticket, previousValues } = totals;
    this.totalRevenue.amount = amount;
    this.totalRevenue.count = count;
    this.totalRevenue.avgticket = avgticket;

    this.totalRevenue.fluctuation = this.dashboardService.getFluctuation(
      previousValues ? previousValues.amount : 0,
      amount
    ).percentage;

    this.totalRevenue.transactionFluctuation =
      this.dashboardService.getFluctuation(
        previousValues ? previousValues.count : 0,
        count
      ).percentage;

    this.totalRevenue.aspFluctuation = this.dashboardService.getFluctuation(
      previousValues ? previousValues.avgticket : 0,
      avgticket
    ).percentage;
    this.isLoading = false;
  }

  private formatLabels(dates: string[], totalDates: number): string[] {
    const intervalDivider = totalDates > 20 ? 6 : 1;
    const interval = Math.floor(totalDates / intervalDivider);
    const labels: Array<string> = [];

    dates.forEach((date, index) => {
      if (index % interval === 0) {
        labels.push(
          new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
          })
        );
      } else {
        labels.push('');
      }
    });

    return labels;
  }

  private getGradientColor(
    context: CanvasRenderingContext2D,
    primaryColorCode: string,
    secondaryColorCode: string
  ): CanvasGradient {
    const gradient = context.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, primaryColorCode);
    gradient.addColorStop(1, secondaryColorCode);
    return gradient;
  }

  private tooltipTitleCallback(context: any): string[] {
    let formattedDate = new Date(
      this.originalDates[context[0].dataIndex]
    ).toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    });

    return [formattedDate];
  }

  private generateLast90Days(): string[] {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 90; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split('T')[0]);
    }
    return dates.reverse();
  }
}
