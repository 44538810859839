<table
  mat-table
  [dataSource]="dataSource"
  class="settlements-table"
  [loader]="isLoading"
>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <div class="last-days-card">{{ element.status }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef>Creation Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.date | date : "MM-dd-yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="paymentDate">
    <th mat-header-cell *matHeaderCellDef>Payment Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.paymentDate | date : "MM-dd-yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="bank">
    <th mat-header-cell *matHeaderCellDef>Bank</th>
    <td mat-cell *matCellDef="let element">{{ element.bankName }}</td>
  </ng-container>

  <ng-container matColumnDef="approxAmount">
    <th mat-header-cell *matHeaderCellDef>
      Approx. <br />
      Amount
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.proportionalValue | formatCurrency }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
