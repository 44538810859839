import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { DecimalPipe } from '@angular/common';
import { CurrencyModel } from '../../../models/currency.model';
import { ConvertCurrencyPipe } from '../../../pipes/convert-currency.pipe';

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
  providers: [DecimalPipe],
})
export class BalancesComponent implements OnInit {
  public merchantBalances: CurrencyModel[] = [
    {
      amount: 0,
      currency: 'BRL',
    },
  ];
  public totalBalances: number = 0;
  public totalBalance: CurrencyModel = {
    amount: 0,
    currency: 'BRL',
  };
  public currency: string = '';
  public isLoading: boolean = false;
  private cacheApi = sessionStorage;

  constructor(
    private dashboardService: DashboardService,
    private convertCurrencyPipe: ConvertCurrencyPipe
  ) {}
  ngOnInit(): void {
    this.isLoading = true;
    // Obter a moeda selecionada do cache
    this.currency = JSON.parse(
      this.cacheApi.getItem('selectedCurrency') || '"USD"'
    );

    this.dashboardService.getBalances(30).subscribe(({ response }) => {
      this.merchantBalances = response;

      // Calcular o saldo total na moeda selecionada
      this.totalBalance.amount = this.merchantBalances.reduce(
        (previousValue, balance) => {
          // Obter a taxa de câmbio da moeda do saldo para a moeda selecionada
          const rateToSelectedCurrency = this.getCurrencyRate(this.currency);
          const rateFromBalanceCurrency = this.getCurrencyRate(
            balance.currency || 'USD'
          );

          if (
            rateToSelectedCurrency !== null &&
            rateFromBalanceCurrency !== null
          ) {
            // Converter o saldo para a moeda selecionada
            const amountInSelectedCurrency =
              (balance.amount / rateFromBalanceCurrency) *
              rateToSelectedCurrency;
            return previousValue + amountInSelectedCurrency;
          } else {
            // Se alguma taxa de câmbio não estiver disponível, você pode escolher ignorar esse saldo
            return previousValue;
          }
        },
        0
      );

      // Formatar o saldo total para duas casas decimais
      this.totalBalance.amount = parseFloat(
        this.totalBalance.amount.toFixed(2)
      );
      // Definir a moeda do saldo total para a moeda selecionada
      this.totalBalance.currency = this.currency;
      this.isLoading = false;
    });
  }

  private getCurrencyRate(currency: string): number | null {
    const currencyData = JSON.parse(
      this.cacheApi.getItem('_cache_values') || '[]'
    ).filter((item: CurrencyModel) => item.asset === currency)[0];

    return currencyData ? currencyData.amount : null; // Supondo que a chave para a taxa seja 'rate'
  }
}
