<div class="card" [ngStyle]="{ background: cardColor }">
  <div
    *ngIf="!noTitle"
    [ngClass]="centerTitle ? 'card-title-center' : 'card-title'"
    [ngStyle]="hasDivider ? { margin: 0 } : { 'margin-bottom': '1rem' }"
  >
    <h3
      [ngStyle]="
        biggerTitle ? { 'font-size': '32px' } : { 'font-size': '22px' }
      "
    >
      {{ cardTitle }}
    </h3>
    <app-last-days-card
      *ngIf="lastDaysCard.id !== 'none'"
      [value]="lastDaysCard"
    ></app-last-days-card>
  </div>
  <hr *ngIf="hasDivider" />
  <div><ng-content></ng-content></div>
</div>
