import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

export interface FormattedPeriod {
  start: string;
  end: string;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor() {}

  public getDate(year: number, month: number, day: number): number {
    return new Date(year, month - 1, day).getTime();
  }

  public getPeriod(period: number): FormattedPeriod {
    let result: FormattedPeriod;
    let startDate: number | null = null;
    let formattedStartDate: string;
    let formattedEndDate: string;

    const now: Date = new Date();

    startDate = new Date().setDate(now.getDate() - period);

    formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US');
    formattedEndDate = formatDate(now, 'yyyy-MM-dd', 'en-US');

    result = {
      end: formattedEndDate,
      start: formattedStartDate,
    };

    return result;
  }

  public getDaysInPeriod(start: string, end: string): number {
    const oneDay: number = 24 * 60 * 60 * 1000;
    const _start: string[] = start.split('-');
    const _end: string[] = end.split('-');

    const startDate: Date = new Date(
      Number(_start[0]),
      Number(_start[1]),
      Number(_start[2])
    );
    const endDate: Date = new Date(
      Number(_end[0]),
      Number(_end[1]),
      Number(_end[2])
    );

    const days: number = Math.round(
      Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
    );

    return days;
  }

  public setPeriod(
    period: number
  ): Array<{ day: string; month: string; year: string }> {
    const result: Array<{ day: string; month: string; year: string }> = [];
    let _date: number;
    let day: string;
    let month: string;
    let year: string;
    let _result: { day: string; month: string; year: string };

    const now: Date = new Date();

    if (!Number.isInteger(period)) {
      return result;
    }

    for (let i = period; i >= 0; i--) {
      _date = new Date().setDate(now.getDate() - i);
      day = formatDate(_date, 'dd', 'en-US');
      month = formatDate(_date, 'MM', 'en-US');
      year = formatDate(_date, 'yyyy', 'en-US');

      _result = {
        day: day,
        month: month,
        year: year,
      };

      result.push(_result);
    }

    day = formatDate(now, 'dd', 'en-US');
    month = formatDate(now, 'MM', 'en-US');
    year = formatDate(now, 'yyyy', 'en-US');

    _result = {
      day: day,
      month: month,
      year: year,
    };

    result.push(_result);

    return result;
  }
}
