<div class="mt-5">
  <div class="row">
    <div class="column">
      <app-card
        style="width: 80%"
        cardTitle="Account Information"
        [hasDivider]="true"
      >
        <div class="row">
          <b class="flex-one">Account Holder </b>
          {{ businessInfo?.corporateName || "-" }}
        </div>

        <div class="row">
          <b class="flex-one">Tax Id </b>
          {{ businessInfo?.person?.taxId || "-" }}
        </div>

        <div class="row">
          <b class="flex-one">Status </b>
          <img
            style="max-width: 22px"
            [src]="
              businessInfo?.enabled
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>
      </app-card>
      <app-card
        style="width: 80%"
        cardTitle="Supporting Documents"
        [hasDivider]="true"
      >
        <div class="row">
          <b class="flex-one">Incorporation Papers </b>
          <img
            style="max-width: 22px"
            [src]="
              true
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>

        <div class="row">
          <b class="flex-one">Board Ellection </b>
          <img
            style="max-width: 22px"
            [src]="
              false
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>

        <div class="row">
          <b class="flex-one">Proof of Address </b>
          <img
            style="max-width: 22px"
            [src]="
              false
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>

        <div class="row">
          <b class="flex-one">Power of Attorney </b>
          <img
            style="max-width: 22px"
            [src]="
              false
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>
      </app-card>
    </div>
    <div class="column">
      <app-card
        *ngFor="let account of bankAccounts"
        style="width: 80%"
        cardTitle="Final Beneficiary Information"
        [hasDivider]="true"
      >
        <div class="row"><b>Bank Name </b> {{ account?.bankName || "-" }}</div>

        <div class="row"><b>Swift Code </b> {{ account?.bankCode || "-" }}</div>

        <div class="row"><b>IBAN </b> {{ account?.iban }}</div>

        <div class="row">
          <b>Account Number </b> {{ account?.accountNumber }}
        </div>
      </app-card>
    </div>
  </div>
</div>
