<div>
  <div class="page-container">
    <div class="row">
      <app-balances></app-balances>
      <app-indices></app-indices>
    </div>
    <div class="row">
      <app-bar-chart></app-bar-chart>
    </div>
    <div class="row mt-4" style="align-items: start">
      <div class="column">
        <app-card
          [cardTitle]="''"
          [lastDaysCard]="{ id: '30sales', amount: 0 }"
        >
          <div class="row">
            <app-donut-chart
              [chartTitle]="'Top Payment <br> Systems'"
              [chartData]="topPaymentSystems.result"
            ></app-donut-chart>
          </div>
        </app-card>
        <app-card
          [cardTitle]="''"
          [lastDaysCard]="{ id: '30sales', amount: 0 }"
        >
          <div class="row">
            <app-donut-chart
              [legendPosition]="'bottom'"
              [chartTitle]="'Top Contracts'"
              [chartData]="topContracts.result"
            ></app-donut-chart>
          </div>
        </app-card>
      </div>
      <app-card [cardTitle]="''">
        <app-settlements-table></app-settlements-table>
      </app-card>
    </div>
  </div>
</div>
