import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download-csv-button',
  templateUrl: './download-csv-button.component.html',
  styleUrls: ['./download-csv-button.component.scss']
})
export class DownloadCsvButtonComponent {
  @Input() buttonText: string = '';
  @Input() loading: boolean = false;
  @Input() csvFilter: any = null;
  @Output() csvFunction = new EventEmitter<string>();
  public csvSeparatorModal: boolean = false;
  public selectedSeparator: string = '';
  public separatorOptions: { value: string; viewValue: string }[] = [];
  private cacheToken = sessionStorage;

  constructor(private reportsService: ReportsService, private paymentsService: PaymentsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loading'] && this.loading === false) {
      this.csvSeparatorModal = false;
    }
  }

  ngOnInit(): void {
    this.reportsService.getSeparatorTypes().subscribe(({ content }) => {
      this.separatorOptions = content.map((value: string) => {
        return {
          value: value,
          viewValue: this.getViewValue(value),
        };
      });
      this.selectedSeparator = this.cacheToken.getItem('userSeparator') || 'SEMICOLON';
    });
  }

  public downloadCsv(){
  if(this.csvFilter){
    this.paymentsService
    .downloadCSV(
      this.csvFilter.reportURL,
      this.csvFilter.start,
      this.csvFilter.end,
      this.csvFilter.search,
      this.csvFilter.pageSize,
      this.csvFilter.page,
      this.selectedSeparator
    )
    .subscribe(
      (csvData: string) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        let realm = environment.theme.split('-')[1];
        saveAs(
          blob,
          this.csvFilter.reportName
            ? `${realm}_${this.csvFilter.reportName}.csv`
            : 'data.csv'
        );
        this.loading = false;
        this.cacheToken.setItem('userSeparator', this.selectedSeparator);
      },
      (error) => {
        console.error('Error downloading CSV:', error);
        alert('Error downloading CSV file');
        this.loading = false;
      }
    );
  this.toggleCSVModal();
  }
  }

  public getViewValue(separator: string): string {
    switch (separator) {
      case 'DOT':
        return '. (dot)';
      case 'SEMICOLON':
        return '; (semicolon)';
      case 'COLON':
        return ': (colon)';
      case 'PIPE':
        return '| (pipe)';
      case 'SLASH':
        return '/ (slash)';
      case 'COMMA':
        return ', (comma)';
      default:
        return separator;
    }
  }


  public toggleCSVModal() {
    this.csvSeparatorModal = !this.csvSeparatorModal;
  }

  public emitCsvDownload(){
    this.cacheToken.setItem('userSeparator', this.selectedSeparator);
    this.csvFunction.emit(this.selectedSeparator)
  }

}
