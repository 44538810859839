import { Component } from '@angular/core';
import { PaymentsService } from '../../services/payments/payments.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent {
  constructor(private paymentsService: PaymentsService) {}

  public rows: any = [];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public startDateValue: string | undefined = undefined;
  public endDateValue: string | undefined = undefined;
  public search: string | undefined = '';
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public displayedColumns: string[] = ['createdAt', 'paymentId', 'message'];
  public dataSource = [];
  public filterParams = {};
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.filterTable();
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public filterTable(page: number = 0) {
    this.isLoading = true;
    this.filterParams = {
      reportURL: 'paymentLogs',
      start: this.startDateValue,
      end: this.endDateValue,
      search: this.search,
      pageSize: this.pageSize,
      page: page,
    };

    this.dataSource = [];
    this.page = page;

    this.paymentsService
      .getReportDateFilter(
        'paymentLogs',
        this.paymentsService.formatDate(this.startDateValue || ''),
        this.paymentsService.formatDate(this.endDateValue || ''),
        this.search,
        page,
        this.pageSize
      )
      .subscribe(({ content, totalElements }) => {
        this.dataSource = content;
        this.selector = totalElements;
        this.isLoading = false;
      });
  }
}
