import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private baseUrl = `${environment.apiURL}/report`;

  constructor(private http: HttpClient) {}

  public getSeparatorTypes() {
    return this.http.get<any>(`${this.baseUrl}/separatorTypes`);
  }


}
