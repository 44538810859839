import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface FirewallRule {
  id?: string;
  contract_id: Array<string> | string;
  rule_type: string;
  rule: string;
  description: string;
  payment_methods: Array<string>;
  created_at?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FirewallRulesService {
  private baseUrl = environment.apiURL + '/firewall';

  constructor(private http: HttpClient) {}

  public getFirewallRules(
    page: number,
    ruleValue: string | undefined
  ): Observable<any> {
    const url = `${this.baseUrl}/list`;

    let queryParams = new HttpParams();

    queryParams = queryParams.append('page', page);
    queryParams = ruleValue
      ? queryParams.append('ruleValue', ruleValue)
      : queryParams;

    return this.http.get(url, { params: queryParams });
  }

  public postFirewallRules(rules: FirewallRule) {
    const url = this.baseUrl + '/block';

    return this.http.post<FirewallRule>(url, rules);
  }

  public getBlockMethods(): Observable<any> {
    const url = this.baseUrl + '/blockMethods';

    return this.http.get(url);
  }

  public postUnblock(id: string | undefined) {
    const url = this.baseUrl + `/${id}/unblock`;

    return this.http.post<FirewallRule>(url, {});
  }

  public postReblock(id: string | undefined) {
    const url = this.baseUrl + `/${id}/block`;

    return this.http.post<FirewallRule>(url, {});
  }
}
