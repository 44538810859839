import { Component, OnInit } from '@angular/core';
import { WalletService } from 'src/app/services/wallet/wallet.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit {

  public balances:{asset: string, balance:number}[]= [];

  constructor(private walletService: WalletService){}

  ngOnInit(): void {
      this.walletService.getPayoutsBalance().subscribe(({balances}) => this.balances = balances)
  }
}
