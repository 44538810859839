<div class="mt-5">
  <mat-form-field
    class="letpay-input mr-5"
    style="min-width: 390px"
    appearance="outline"
  >
    <mat-label>Search:</mat-label>
    <input
      [(ngModel)]="transactionId"
      placeholder="Payment token or reference ID"
      class="transaction-input"
      matInput
    />
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="checkAvailableRefund()">
    Submit
  </button>

  <div class="row" *ngIf="paymentIsRefundable && refundInfo">
    <app-card cardTitle="Refund Info" [hasDivider]="true">
      <div class="row mt-3 ml-1 mb-2" *ngFor="let item of refundList">
        <div class="column" style="align-items: start; width: 200px">
          <b>{{ item.label }}</b>
        </div>
        <div class="column" style="align-items: start">
          {{ refundInfo[item.value] || "-" }}
        </div>
      </div></app-card
    >
    <app-card cardTitle="Refund Amount">
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Amount:</mat-label>
          <input [(ngModel)]="amountToRefund" matInput />
        </mat-form-field>
      </div>

      <div *ngIf="isOxxoSpei">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Bank Code:</mat-label>
            <input [(ngModel)]="refundData.bankCode" matInput />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Clabe:</mat-label>
            <input [(ngModel)]="refundData.clabe" matInput />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isBoleto">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Bank Number:</mat-label>
            <input [(ngModel)]="refundData.bankNumber" matInput />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Agency Number:</mat-label>
            <input [(ngModel)]="refundData.agancyNumber" matInput />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Agency Digit:</mat-label>
            <input [(ngModel)]="refundData.agencyDigit" matInput />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Account Number:</mat-label>
            <input [(ngModel)]="refundData.accountNumber" matInput />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Account Digit:</mat-label>
            <input [(ngModel)]="refundData.accountDigir" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <button
          [loader]="isLoading"
          (click)="refundTransaction()"
          mat-flat-button
          color="primary"
        >
          Refund
        </button>
      </div>
    </app-card>
  </div>
</div>
<div class="row" *ngIf="!paymentIsRefundable">
  <app-card [cardTitle]="''" [cardColor]="'#e76e6c'" [noTitle]="true">
    <div class="row">Payment {{ transactionId }} is not refundable!</div>
  </app-card>
</div>

<div class="row" *ngIf="paymentRefunded">
  <app-card [cardTitle]="''" [cardColor]="'#dff0d8'" [noTitle]="true">
    <div class="row">Payment {{ transactionId }} was refunded!</div>
  </app-card>
</div>
