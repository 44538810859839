<div class="column-stretch">
  <app-card [cardTitle]="'Balances'">
    <table class="balance-table" [loader]="isLoading">
      <tr>
        <th>Currency</th>
        <th>Amount</th>
        <th>{{ currency }}</th>
      </tr>
      <tr *ngFor="let balance of merchantBalances">
        <td>{{ balance?.currency }}</td>
        <td>
          {{
            balance.amount
              | formatCurrencyFromTo
                : balance.currency
                : balance.currency
                : false
          }}
        </td>
        <td>
          {{
            balance.amount
              | formatCurrencyFromTo : currency : balance.currency : false
          }}
        </td>
      </tr>
    </table>
  </app-card>
  <app-card [cardTitle]="'Total Balance'">
    <div class="row ml-1">
      <h1 class="mr-5 mt-3" [loader]="isLoading">
        {{ totalBalance.amount | formatCurrency : currency : true : false }}
      </h1>
      <app-line-chart class="ml-5"></app-line-chart>
    </div>
    <!-- <div class="row ml-1">
      <p>xxxxxx Orders</p>
      <app-percentage-variation-card></app-percentage-variation-card>
    </div> -->
  </app-card>
</div>
