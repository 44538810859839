import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
})
export class StatusCardComponent {
  @Input() public status: boolean = true;
  public style: Object = {};

  ngOnChanges(): void {
    if (this.status) {
      this.style = { background: '#00D68F' };
    } else {
      this.style = { background: '#D60040' };
    }
  }
}
