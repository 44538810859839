<div class="mt-5 row">
  <div class="column align-items-start statement-column">
    <div class="row align-items-center title-row">
      <h1 class="statement-title">Statement {{ statementId }}</h1>
      <div class="button-group">
        <!-- <button class="submit-btn">CSV</button>
        <button class="submit-btn">PDF</button> -->
      </div>
    </div>
    <div class="row">
      <app-card [cardTitle]="''" class="custom-card">
        <div class="card-content">
          <div class="card-row" style="font-size: 20px">
            <strong class="first-row">Statement ID</strong>
            <span
              ><strong>{{ statementId }}</strong></span
            >
            <div></div>
          </div>
          <hr class="card-divider" />
          <div class="card-row">
            <div class="first-row">Creation Date</div>
            <span>{{ dataSource.creationDate }}</span>
            <div></div>
          </div>
          <hr class="card-divider" />
          <div class="card-row">
            <div class="first-row">Invoices Generated</div>

            <span>{{ dataSource.invoices.length }}</span>
            <div></div>
          </div>
          <hr class="card-divider" />
          <div *ngFor="let territory of territories">
            <div class="card-row">
              <strong class="first-row">Territory</strong>
              <span
                ><strong>{{ territory.countryCode }}</strong></span
              >
              <div></div>
            </div>
            <div class="card-row">
              <div class="first-row">Transactions Included</div>
              <span>{{ territory.transactionCount }}</span>
              <div></div>
            </div>
            <div class="card-row" *ngFor="let fee of territory.fees">
              <div class="first-row">{{ feesEnum[fee.feeSubtype] }}</div>
              <span>{{ fee.amount.toPrecision(2) }}</span>
              <div>{{ fee.currencyCode }}</div>
            </div>
            <hr class="card-divider" />
          </div>
          <div *ngFor="let total of dataSource.totals">
            <div class="card-row">
              <strong class="first-row"
                >Total Amount Due {{ total.currencyCode }}</strong
              >
              <span
                ><strong>{{ total.amount.toPrecision(2) }}</strong></span
              >
              <div>
                <strong>{{ total.currencyCode }}</strong>
              </div>
            </div>
            <hr cl ass="card-divider" />
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <div class="column align-items-start invoice-column">
    <div class="row align-items-center title-row">
      <h1 class="invoice-title mb-5">Invoices linked to this Statement</h1>
    </div>
    <div class="row" *ngFor="let invoice of invoices">
      <app-card [cardTitle]="''" class="custom-card">
        <div class="card-content">
          <div class="card-row" style="font-size: 20px">
            <strong class="first-row">Invoice ID</strong>
            <span
              ><strong>{{ invoice.id }}</strong></span
            >
            <div></div>
          </div>
          <div class="card-divider"></div>
          <div class="card-row">
            <strong class="first-row">Creation Date</strong>
            <span>{{ invoice.createdAt.split("T")[0] }}</span>
            <div></div>
          </div>
          <div class="card-row">
            <strong class="first-row">Generated by Statement</strong>
            <span>{{ statementId || "-" }}</span>
            <div></div>
          </div>
          <div class="card-divider"></div>
          <div class="card-row">
            <strong class="first-row">Amount Due</strong>
            <span
              ><strong>{{ invoice.amount.toPrecision(2) }}</strong></span
            >
            <div>
              <strong>{{ invoice.currencyCode }}</strong>
            </div>
          </div>
          <div class="card-row">
            <strong class="first-row">Due Date</strong>
            <span>{{ invoice.dueDate }}</span>
            <div></div>
          </div>
          <div class="card-row">
            <strong class="first-row">Status</strong>
            <app-pending-status-card [status]="invoice.status" />
            <div></div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
