<div>
  <mat-form-field appearance="outline">
    <mat-label>Search:</mat-label>
    <input #searchInput (keyup)="onKeyUp(searchInput.value)" matInput />
  </mat-form-field>

  <button
    [disabled]="disabledWhileRequest"
    mat-flat-button
    color="primary"
    (click)="toggleCSVModal()"
  >
    CSV
  </button>
</div>

<app-modal
  title="Settings"
  [openModal]="CSVSeparatorModal"
  (closeModalRequest)="toggleCSVModal()"
>
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>CVS Separator</mat-label>
      <mat-select
        [(ngModel)]="selectedSeparator"
        placeholder="Select a CVS Separator"
      >
        <mat-option
          *ngFor="let separator of separatorOptions"
          [value]="separator.value"
        >
          {{ separator.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <button class="disable-btn" (click)="toggleCSVModal()">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="customParams ? customCSVFunction() : downloadCSV()"
    >
      Save
    </button>
  </div>
</app-modal>
