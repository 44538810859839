import { Component } from '@angular/core';
import { PaymentsService } from '../../../services/payments/payments.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss'],
})
export class TransfersComponent {
  constructor(private paymentsService: PaymentsService) {}

  public rows: any = [];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public search: string | undefined = undefined;
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public isLoading: boolean = false;

  public displayedColumns: string[] = [
    'collectionCycle',
    'paymentId',
    'externalId',
    'merchantFromId',
    'contractId',
    'destinationCountry',
    'status',
  ];
  public dataSource = [];

  ngOnInit(): void {
    this.filterTable();
  }

  public onPageChange(event: PageEvent) {
    this.filterTable(event.pageIndex);
  }

  public filterTable(page = 0) {
    this.isLoading = true;
    this.page = page;
    this.paymentsService
      .postReportDateFilter(
        'totalPaymentCycles',
        this.startDate,
        this.endDate,
        this.search,
        this.page
      )
      .subscribe(({ response }) => {
        this.dataSource = response;
        this.isLoading = false;
      });
  }

  public downloadCSV() {
    this.paymentsService.downloadCSV(
      'totalPaymentCycles',
      this.startDate,
      this.endDate,
      this.search,
      this.page
    );
  }
}
