import { Component } from '@angular/core';
import { Contract, ProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-id-projects',
  templateUrl: './id-projects.component.html',
  styleUrls: ['./id-projects.component.scss'],
})
export class IdProjectsComponent {
  public data: any = {
    numbers: 455,
    description: 'Valids',
    fluctuation: 0.9,
  };

  public rows: any = [];
  public projectsList: Contract[] = [];

  public displayedColumns: string[] = [
    'paymentId',
    'merchantFromId',
    'paymentMethod',
    'amount',
    'currencyCode',
    'cancellationDate',
  ];
  public dataSource : Array<any> = [];
  public selectedProject: Contract = {
    active: true,
    createdAt: '',
    id: '',
    methods: [],
    name: '',
  };
  public paymentMethodsBrasil: any = [];
  public paymentMethodsMexico: any = [];


  constructor(private projectsService: ProjectsService) {}

  ngOnInit() {
    this.projectsService.getProjects().subscribe(({ contracts }) => {
      this.projectsList = contracts.filter(
        (contract: any) => contract.contractType === 'ID_VALIDATION'
      );

      contracts.forEach((element: any) => {
        element.methods.forEach((method: any) => {
            method.fees.forEach((fee: any) => {
                if (!this.dataSource.some(e => this.isFeeEqual(e, fee))) {
                    this.dataSource.push(fee);
                }
            });
        });
    });
    
      this.dataSource.sort((a, b) => a.method.localeCompare(b.method));
      this.checkPaymentMethods();
    });

  }
  
  private isFeeEqual(fee1: any, fee2: any): boolean {
    return fee1.method === fee2.method &&
           fee1.type === fee2.type &&
           fee1.value === fee2.value;
}


  public checkPaymentMethods() {
    for (let entry of this.projectsList) {
      if (
        entry.methods &&
        Array.isArray(entry.methods) &&
        entry === this.selectedProject
      ) {
        for (let method of entry.methods) {
          if (method.active && method.country === 'Brasil') {
            this.paymentMethodsBrasil.push(method);
          } else if (method.active && method.country === 'Mexico') {
            this.paymentMethodsMexico.push(method);
          }
        }
      }
    }
  }
}
