import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PaymentsService } from '../../services/payments/payments.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { RefundsService } from 'src/app/services/refund/refunds.service';
import { DataService } from '../../services/data/data.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DisputesService } from '../../services/disputes/disputes.service';
import { getViewValue } from 'src/assets/common/functions/functions';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() filterData: any = {};
  @Input() hidePDF: boolean = false;
  @Input() filterDataCsv: any = {};
  @Input() customParams: boolean = false;
  @Input() customSearchFunction: () => void = () => {};
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();
  @Output() customCSV: EventEmitter<any> = new EventEmitter();
  public CSVSeparatorModal: boolean = false;
  public separatorOptions: { value: string; viewValue: string }[] = [];
  public disabledWhileRequest: boolean = false;
  public selectedSeparator: string = '';
  public dataSource: Array<any> = [];
  private cacheToken = sessionStorage;
  private searchSubject: Subject<string> = new Subject();

  constructor(
    private paymentsService: PaymentsService,
    private reportsService: ReportsService,
    private dataService: DataService,
    private clipboard: Clipboard
  ) {
    this.searchSubject.pipe(debounceTime(600)).subscribe((searchValue) => {
      this.searchEvent.emit(searchValue);
    });
    this.dataService.dataSource$.subscribe((data) => {
      this.dataSource = data;
    });
  }

  ngOnInit(): void {
    this.reportsService.getSeparatorTypes().subscribe(({ content }) => {
      this.separatorOptions = content.map((value: string) => {
        return {
          value: value,
          viewValue: getViewValue(value),
        };
      });
      this.selectedSeparator = this.cacheToken.getItem('userSeparator') || '';
    });
  }

  public onKeyUp(searchValue: string) {
    this.searchSubject.next(searchValue);
  }

  public toggleCSVModal() {
    this.CSVSeparatorModal = !this.CSVSeparatorModal;
  }

  public downloadCSV() {
    if (!this.filterDataCsv.reportURL) {
      this.filterDataCsv = this.filterData;
    }
    this.disabledWhileRequest = true;
    this.paymentsService
      .downloadCSV(
        this.filterDataCsv.reportURL,
        this.filterDataCsv.start,
        this.filterDataCsv.end,
        this.filterDataCsv.search,
        this.filterDataCsv.pageSize,
        this.filterDataCsv.page,
        this.selectedSeparator
      )
      .subscribe(
        (csvData: string) => {
          const blob = new Blob([csvData], { type: 'text/csv' });
          let realm = environment.theme.split('-')[1];
          saveAs(
            blob,
            this.filterDataCsv.reportName
              ? `${realm}_${this.filterDataCsv.reportName}.csv`
              : 'data.csv'
          );
          this.disabledWhileRequest = false;
          this.cacheToken.setItem('userSeparator', this.selectedSeparator);
        },
        (error) => {
          console.error('Error downloading CSV:', error);
          alert('Error downloading CSV file');
          this.disabledWhileRequest = false;
        }
      );
    this.toggleCSVModal();
  }

  public copySearchResults() {
    const dataToCopy = JSON.stringify(this.dataSource);
    this.clipboard.copy(dataToCopy);
  }

  public customCSVFunction() {
    this.cacheToken.setItem('userSeparator', this.selectedSeparator);
    this.customCSV.emit(this.selectedSeparator);
  }

}
