<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="requestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="startDateValue"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="endDateValue"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="requestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #requestDate></mat-date-range-picker>
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="updateChart()">
      Filter
    </button>
  </app-fieldset>

  <div class="row">
    <div class="row align-right">
      <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [legend]="lineChartLegend"
        [type]="lineChartType"
      >
      </canvas>
    </div>
    <app-card
      cardTitle="Payment Methods"
      [centerTitle]="true"
      class="container-no-height"
    >
      <div class="payment-options column-no-align">
        <label
          ><input
            type="checkbox"
            [(ngModel)]="selectedOptions.creditCard"
            (change)="updateChart()"
          />
          Credit Card</label
        >
        <label
          ><input
            type="checkbox"
            [(ngModel)]="selectedOptions.pix"
            (change)="updateChart()"
          />
          Pix</label
        >
        <label
          ><input
            type="checkbox"
            [(ngModel)]="selectedOptions.boletoBancario"
            (change)="updateChart()"
          />
          Boleto Bancário</label
        >

        <label
          ><input
            type="checkbox"
            [checked]="areAllSelected()"
            (change)="toggleAll()"
          />
          Select All</label
        >
      </div>
    </app-card>
  </div>
</div>
