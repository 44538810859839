import { Component } from '@angular/core';
import { MerchantService } from '../../../services/merchant/merchant.service';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss'],
})
export class BankAccountsComponent {
  public bankAccounts: any = { content: {} };
  public businessInfo: any = {};

  constructor(private merchantService: MerchantService) {}

  ngOnInit(): void {
    this.merchantService.getAccounts().subscribe(({ content }: any) => {
      this.bankAccounts = content;
    });

    this.merchantService.getMerchant().subscribe((response) => {
      this.businessInfo = response;
    });
  }
}
