<div class="row align-right">
  <button class="white-btn" (click)="backToPayouts()">Back</button>
</div>
<div class="row align-right">
  <button
    mat-flat-button
    color="primary"
    *ngIf="transactionInfo.general_info.transaction_status === 'COMPLETED'"
    (click)="downloadReceipt()"
  >
    Download Receipt
  </button>
</div>
<div class="row mt-5" style="align-items: start; gap: 20px" [loader]="loading">
  <div class="column">
    <app-card cardTitle="General Info" style="width: 90%" [hasDivider]="true">
      <div class="row mt-3 ml-1 mb-2" *ngFor="let item of generalInfoList">
        <div class="column" style="align-items: start; width: 200px">
          <b>{{ item.label }}</b>
        </div>
        <div
          *ngIf="item.label !== 'Transaction Status'; else statusInput"
          class="column"
          style="align-items: start"
        >
          {{ transactionInfo.general_info[item.value] || "-" }}
        </div>

        <ng-template #statusInput>
          <div class="column" style="align-items: start">
            {{
              (transactionInfo.general_info[item.value] | splitUnderscore) ||
                "-"
            }}
          </div>
          <!-- <div class="column" style="align-items: start">
              <select
                style="margin-bottom: 5px"
                class="form-control"
                id="paymentStatusSelect"
                [(ngModel)]="transactionInfo.general_info.transaction_status"
                (change)="checkForValues()"
              >
                <option *ngFor="let option of payoutStatus" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div> -->
        </ng-template>
      </div>
    </app-card>

    <app-card
      cardTitle="Beneficiary Info"
      style="width: 90%"
      [hasDivider]="true"
    >
      <div class="row mt-3 ml-1 mb-2" *ngFor="let item of beneficiaryInfoList">
        <div class="column" style="align-items: start; width: 200px">
          <b>{{ item.label }}</b>
        </div>
        <div
          class="column"
          style="align-items: start"
          *ngIf="item.label !== 'Person ID'; else personRedirect"
        >
          {{ transactionInfo?.customer_info[item.value] || "-" }}
        </div>
        <ng-template #personRedirect>
          <div class="column" style="align-items: start">
            <a
              [routerLink]="['/person']"
              [queryParams]="{ id: transactionInfo?.customer_info[item.value] }"
              >{{ transactionInfo?.customer_info[item.value] }}</a
            >
          </div>
        </ng-template>
      </div></app-card
    >
  </div>

  <div class="column mr-5">
    <app-card cardTitle="Payout Info" style="width: 90%" [hasDivider]="true">
      <div class="row mt-3 ml-1 mb-2" *ngFor="let item of payoutInfoList">
        <div class="column" style="align-items: start; width: 200px">
          <b>{{ item.label }}</b>
        </div>
        <div class="column" style="align-items: start">
          {{ transactionInfo?.payment_info[item.value] || "-" }}
        </div>
      </div>
    </app-card>
  </div>
</div>
