export enum BlockMethods {
  EMAIL = 'Email',
  TAXID = 'Tax Id',
  IP = 'IP',
  CREDIT_CARD_NUMBER = 'Credit Card Number',
  BIN = 'Bin',
  RANGE_BIN = 'Range Bin',
  RANGE_IP = 'Range IP',
}

export enum PaymentMethods {
  BITCOIN = 'Bitcoin',
  BOLETO = 'Boleto Bancário',
  CREDITCARD = 'Credit Card',
  DEBITCARD = 'Debit Card',
  NOT_DEFINED = 'Undefined',
  OXXO = 'Oxxo',
  PIX = 'Pix',
  RIPPLE = 'Ripple',
  SPEI = 'Spei',
  TEDDOC = 'Ted or Doc',
}

export enum Fees {
  SERVICE_FEE = 'Service Fee',
  TAX = ' Tax',
  SETTLEMENT = 'Settlement',
  CUSTOMER_FEE = 'Customer Fee',
  OTHER = 'Other',
}
