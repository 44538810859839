<div
  #modalOverlay
  *ngIf="openModal"
  (keydown.esc)="close()"
  tabindex="-1"
  class="modal-overlay"
>
  <div
    [style.min-width]="minWidth"
    (click)="$event.stopPropagation()"
    class="modal-content"
  >
    <div class="modal-header" *ngIf="title || showCloseButton">
      <h2 *ngIf="title">{{ title }}</h2>
      <button *ngIf="showCloseButton" (click)="close()" class="modal-close-button">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <path *ngIf="theme === 'skin-epag'"
        d="M15.5237 3.12242C16.1588 2.4873 16.1588 1.45758 15.5237 0.82247L15.1775 0.476334C14.5424 -0.158778 13.5127 -0.158778 12.8776 0.476335L8 5.35392L3.12242 0.476336C2.4873 -0.158776 1.45758 -0.158778 0.822469 0.476335L0.476334 0.82247C-0.158778 1.45758 -0.158778 2.4873 0.476335 3.12242L5.35392 8L0.476336 12.8776C-0.158777 13.5127 -0.158778 14.5424 0.476334 15.1775L0.82247 15.5237C1.45758 16.1588 2.4873 16.1588 3.12241 15.5237L8 10.6461L12.8776 15.5237C13.5127 16.1588 14.5424 16.1588 15.1775 15.5237L15.5237 15.1775C16.1588 14.5424 16.1588 13.5127 15.5237 12.8776L10.6461 8L15.5237 3.12242Z"
        fill="#016197"
      />
      <path *ngIf="theme === 'skin-letpay'"
        d="M15.5237 3.12242C16.1588 2.4873 16.1588 1.45758 15.5237 0.82247L15.1775 0.476334C14.5424 -0.158778 13.5127 -0.158778 12.8776 0.476335L8 5.35392L3.12242 0.476336C2.4873 -0.158776 1.45758 -0.158778 0.822469 0.476335L0.476334 0.82247C-0.158778 1.45758 -0.158778 2.4873 0.476335 3.12242L5.35392 8L0.476336 12.8776C-0.158777 13.5127 -0.158778 14.5424 0.476334 15.1775L0.82247 15.5237C1.45758 16.1588 2.4873 16.1588 3.12241 15.5237L8 10.6461L12.8776 15.5237C13.5127 16.1588 14.5424 16.1588 15.1775 15.5237L15.5237 15.1775C16.1588 14.5424 16.1588 13.5127 15.5237 12.8776L10.6461 8L15.5237 3.12242Z"
        fill="#4a0044"
      />
        </svg>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
