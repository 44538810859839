import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-percentage-variation-card',
  templateUrl: './percentage-variation-card.component.html',
  styleUrls: ['./percentage-variation-card.component.scss'],
})
export class PercentageVariationCardComponent implements OnChanges {
  @Input() value: number = 0;
  public style: Object = {};

  constructor() {}

  ngOnChanges(): void {
    if (this.value > 0) {
      this.style = { background: 'rgba(0, 117, 255, 0.20)', color: '#1B98E0' };
    } else if (this.value < 0) {
      this.style = { background: '#ccc', color: 'red' };
    } else {
      this.style = { background: 'rgba(255, 170, 0, 0.20)', color: '#FA0' };
    }
  }
}
