import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel } from '../models/currency.model';

@Pipe({
  name: 'formatCurrencyFromTo',
  pure: false,
})
export class FormatCurrencyFromToPipe implements PipeTransform {
  private cacheAPI = sessionStorage;

  transform(
    value: number,
    fromCurrency: string = 'USD',
    toCurrency: string = 'USD',
    showCurrencySymbol: boolean = true
  ): string {
    const fromCurrencyRate = this.getCurrencyRate(fromCurrency);

    const toCurrencyRate = this.getCurrencyRate(toCurrency);

    // const toCurrencySymbol = this.getCurrencySymbol(toCurrency);

    const fromCurrencySymbol = this.getCurrencySymbol(fromCurrency);

    if (value && fromCurrencyRate && toCurrencyRate) {
      const convertedValue = ((value * fromCurrencyRate) / toCurrencyRate)
        .toFixed(2)
        .toString();
      let formattedValue = this.formatNumber(convertedValue);

      if (showCurrencySymbol) {
        return `${fromCurrencySymbol} ${formattedValue}`;
      }
      return `${formattedValue}`;
    }
    return '';
  }

  private getCurrencyRate(currency: string): number | null {
    const currencyData = JSON.parse(
      this.cacheAPI.getItem('_cache_values') || '[]'
    ).filter((item: CurrencyModel) => item.asset === currency)[0];

    return currencyData ? currencyData.amount : null;
  }

  private getCurrencySymbol(currency: string): string {
    const currencyData = JSON.parse(
      this.cacheAPI.getItem('_cache_assets') || '[]'
    ).filter((item: CurrencyModel) => item.asset === currency)[0];

    return currencyData ? currencyData.symbol : '';
  }

  private formatNumber(numberString: string): string {
    let [wholePart, decimalPart] = numberString.split('.');
    wholePart = wholePart.replace(/^0+/, '');

    if (!wholePart.length) {
      wholePart = '0';
    }

    return wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimalPart;
  }
}
