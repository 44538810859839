import { Component, ViewChild, OnInit } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { IdValidationService } from '../../../services/id-validation/id-validation.service';
import { PaymentsService } from '../../../services/payments/payments.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-id-transaction-reports',
  templateUrl: './id-transaction-reports.component.html',
  styleUrls: ['./id-transaction-reports.component.scss'],
})
export class IdTransactionReportsComponent {
  @ViewChild(BaseChartDirective, { static: true }) chart:
    | BaseChartDirective
    | undefined;

  constructor(
    private idValidationService: IdValidationService,
    private paymentsService: PaymentsService
  ) {
    this.startDateValue = this.paymentsService.getOneMonthAgoDate();
    this.endDateValue = this.paymentsService.getCurrentDate()
  }

  public data: any = {
    numbers: 455,
    description: 'Documents',
    fluctuation: 0.9,
  };

  public lineChartOptions: ChartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: 'Amount of document validation',
        align: 'start',
        font: {
          size: 22,
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
          footer: function (tooltipItems) {
            let total = 0;
            tooltipItems.forEach(function (tooltipItem) {
              total += tooltipItem.parsed.y;
            });
            return 'Total Documents: ' + total;
          },
        },
      },
    },
  };

  public lineChartData: ChartDataset[] = [
    {
      data: [],
      label: 'Invalid Document',
      borderColor: '#d60040',
      pointBackgroundColor: '#d60040',
    },
    {
      data: [],
      label: 'Valid Document',
      borderColor: '#00d68f',
      pointBackgroundColor: '#00d68f',
    },
    {
      data: [],
      label: 'Total Documents',
      borderColor: '#295da0',
      pointBackgroundColor: '#295da0',
    },
  ];

  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartLabels = ['Janeiro', 'Fevereiro', 'Março', 'Abril'];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public startDateValue: string | undefined = undefined;
  public endDateValue: string | undefined = undefined;
  public search: string | undefined = undefined;
  public filterParams = {};
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public isLoading: boolean = true;

  public rows: any = [];

  public displayedColumns: string[] = [
    'paymentId',
    'merchantFromId',
    'paymentMethod',
    'amount',
  ];
  public dataSource = [];

  ngOnInit(): void {
    this.isLoading = true;
    this.filterTable();

    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 12);

    const formattedToday = this.paymentsService.formatDate(
      today.toISOString().split('T')[0]
    );
    const formattedSixMonthsAgo = this.paymentsService.formatDate(
      sixMonthsAgo.toISOString().split('T')[0]
    );

    this.idValidationService
      .getIdValidationSummary(formattedSixMonthsAgo, formattedToday)
      .subscribe(({ summary }: any) => {
        summary = summary.reverse();

        const periods = summary.map((item: any) => item.period);
        const validCounts = summary.map((item: any) => item.valid);
        const invalidCounts = summary.map((item: any) => item.invalid);

        this.lineChartLabels = periods;
        this.lineChartData[0].data = invalidCounts;
        this.lineChartData[1].data = validCounts;
        this.lineChartData[2].data = validCounts.map(
          (val: number, index: number) => val + invalidCounts[index]
        );

        this.chart?.update();
        this.isLoading = false;
      });
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public filterTable(page: number = 0, searchParam: string = '') {
    this.isLoading = true;
    this.filterParams = {
      start: this.startDateValue,
      end: this.endDateValue,
      search: searchParam,
      pageSize: this.pageSize,
      page: page,
    };

    this.dataSource = [];
    this.page = page;

    this.idValidationService
      .getIdValidation(
        this.paymentsService.formatDate(this.startDateValue || ''),
        this.paymentsService.formatDate(this.endDateValue || ''),
        searchParam,
        page,
        this.pageSize
      )
      .subscribe(({ content, totalElements }) => {
        this.dataSource = content;
        this.selector = totalElements;
        this.isLoading = false;
      });
    this.getAmountValidation();

  }

  public getAmountValidation(){

    this.idValidationService.getValidDocuments( this.paymentsService.formatDate(this.startDateValue || ''),
    this.paymentsService.formatDate(this.endDateValue || ''),).subscribe(({ amount }) => {
      this.data.numbers = amount;
    });
  }
}
