import {
  Directive,
  Input,
  ComponentFactoryResolver,
  ViewContainerRef,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { LoaderComponent } from '../components/loader/loader.component';

@Directive({
  selector: '[loader]',
})
export class LoaderDirective {
  private isLoading = false;
  private componentRef: any;

  constructor(
    private el: ElementRef,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2
  ) {}

  @Input() set loader(condition: boolean) {
    if (condition && !this.isLoading) {
      if (this.componentRef) {
        this.componentRef.destroy();
      }

      this.renderer.addClass(this.el.nativeElement, 'hidden-content');

      const factory =
        this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
      this.componentRef = this.viewContainer.createComponent(factory);
      this.isLoading = true;
    } else if (!condition && this.isLoading) {
      this.renderer.removeClass(this.el.nativeElement, 'hidden-content');

      this.componentRef.destroy();
      this.isLoading = false;
    }
  }
}
