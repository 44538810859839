import { Component, EventEmitter, Output } from '@angular/core';
import { CurrencyModel } from '../../models/currency.model';
import { CurrencyService } from '../../services/currency/currency.service';

@Component({
  selector: 'app-currency-modal',
  templateUrl: './currency-modal.component.html',
  styleUrls: ['./currency-modal.component.scss'],
})
export class CurrencyModalComponent {
  @Output() closeModalRequest = new EventEmitter<void>();

  public currencies: any = [];
  public selectedCurrency: any = { asset: '', amount: 0 };
  private cacheAPI = sessionStorage;

  constructor(private currencyService: CurrencyService) {}

  ngOnInit() {
    this.currencies = JSON.parse(
      this.cacheAPI.getItem('_cache_values') || '[]'
    );
    this.selectedCurrency = JSON.parse(
      this.cacheAPI.getItem('selectedCurrency') || '"USD"'
    );
  }

  public onCurrencyChange() {
    this.currencyService.current = this.selectedCurrency.asset || '';
    this.cacheAPI.setItem(
      'selectedCurrency',
      JSON.stringify(this.selectedCurrency)
    );
    this.close();
    location.reload();
  }

  public close() {
    this.closeModalRequest.emit();
  }
}
