import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataSourceSubject = new BehaviorSubject<any[]>([]);
  public dataSource$ = this.dataSourceSubject.asObservable();

  public setData(data: any[]) {
    this.dataSourceSubject.next(data);
  }
}
