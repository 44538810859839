<div style="justify-content: center">
  <div class="indices-container">
    <div *ngFor="let indices of data">
      <app-card
        class="app-card"
        [cardTitle]="indices.title"
        [lastDaysCard]="{ id: indices.period, amount: indices.fluctuation }"
      >
        <h2
          *ngIf="indices.id !== 'sales'; else salesNumber"
          [loader]="isLoading"
        >
          {{ (indices.numbers | formatThousand) || 0 }}
        </h2>
        <ng-template #salesNumber>
          <h2 [loader]="isLoading">
            {{ (indices.numbers | formatCurrency : currency) || 0 }}
          </h2>
        </ng-template>
        <div class="description-line">
          <p>{{ indices.description }}</p>
          <app-percentage-variation-card
            [value]="indices.fluctuation"
          ></app-percentage-variation-card>
        </div>
      </app-card>
    </div>
  </div>
</div>
