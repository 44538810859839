<div style="max-width: 166px">
  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [type]="lineChartType"
    [plugins]="lineChartPlugins"
  >
  </canvas>
</div>
