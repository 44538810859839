/* eslint-disable no-mixed-spaces-and-tabs */
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AuthService,
  AuthenticationData,
} from 'src/app/services/auth/auth-service.service';
import { environment } from 'src/environments/environment';
import { CurrencyService } from '../../services/currency/currency.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public loginForm: FormGroup;
  public error = false;
  public logoPath: string;
  public logoWidth: number;
  public theme: string;
  public hasError: boolean = true;
  public authError: string = '';
  public siteKey: string = '6LfzhtkiAAAAAE-1dKn1hzY1JFmifvhpEIdM9Vt1';
  public tokenRecaptcha: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private currencyService: CurrencyService,
  ) {
    this.theme = environment.theme;

    this.logoWidth = this.theme === 'skin-epag' ? 240 : 440;

    this.logoPath =
      this.theme === 'skin-epag'
        ? '/assets/epag/logos/logo.svg'
        : '/assets/letpay/logos/logo.svg';

    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      captcha: new FormControl('', Validators.required),
    });
  }

  public handleSuccess(token: string) {
    this.hasError = false;
    this.authService.setRecaptcha(token);
    this.loginForm.controls['captcha'].setValue(token);
  }

  public onSubmit(): void {
    if (this.loginForm.valid && this.loginForm.controls['captcha'].value) {
      this.authService.authenticateUser(this.loginForm.value).subscribe({
        next: (response: AuthenticationData) => {
          this.currencyService.getCurrencies();
          this.authService.setAuthenticationData(response);
          this.authService.setPassword(btoa(this.loginForm.value.password));

          window.location.href = '/dashboard';
        },
        error: (error) => {
          this.authService.wait = false;
          this.hasError = true;
          this.authError = 'Wrong username or password.';
          this.router.navigate(['/']);
        },
      });
    } else {
      this.hasError = true;
      this.authError = 'Please solve the captcha before logging in.';
    }
  }
}
