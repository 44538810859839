<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="requestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="startDate"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="endDate"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="requestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #requestDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Search:</mat-label>
      <input [(ngModel)]="searchParam" #searchInput matInput />
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="filterAllTables()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Downloads">
    <app-download-csv-button
      (csvFunction)="getDisputesCsv($event)"
      buttonText="Disputes"
    ></app-download-csv-button>
  </app-fieldset>

  <div>
    <h3>Attention Required</h3>

    <ngx-simplebar [options]="{ autoHide: false }" style="overflow-y: hidden">
      <div class="row">
        <app-card [cardTitle]="''" style="margin-right: 16px !important">
          <div>
            <table
              mat-table
              [dataSource]="dataSourceAttention"
              class="transactions-table"
              cdk-virtual-scroll
              [loader]="isLoading"
            >
              <ng-container matColumnDef="disputeId">
                <th mat-header-cell *matHeaderCellDef>Dispute ID</th>
                <td mat-cell *matCellDef="let element">
                  <a
                    [routerLink]="[
                      '/payments/resolution-center/dispute-details/',
                      element.id
                    ]"
                  >
                    {{ element.id }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="disputeDate">
                <th mat-header-cell *matHeaderCellDef>Dispute Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.disputeDate | date : "yyyy-MM-dd:HH:mm:ss" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" class="centered-cell">
                  <app-dispute-status-card [text]="element.status" />
                </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>country</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.country }}
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amount }}
                </td>
              </ng-container>

              <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef>Currency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.currency }}
                </td>
              </ng-container>

              <ng-container matColumnDef="transactionId">
                <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.transactionId }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </app-card>
      </div>
    </ngx-simplebar>
    <div style="max-width: 70%">
      <mat-paginator
        [pageSizeOptions]="[5, 10]"
        [pageSize]="pageSizeAttention"
        [length]="selector?.totalElements"
        (page)="onPageChangeAttention($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>

  <div>
    <h3>On Going</h3>

    <ngx-simplebar [options]="{ autoHide: false }" style="overflow-y: hidden">
      <div class="row">
        <app-card [cardTitle]="''" style="margin-right: 16px !important">
          <div>
            <table
              mat-table
              [dataSource]="dataSourceOnGoing"
              class="transactions-table"
              cdk-virtual-scroll
              [loader]="isLoading"
            >
              <ng-container matColumnDef="disputeId">
                <th mat-header-cell *matHeaderCellDef>Dispute ID</th>
                <td mat-cell *matCellDef="let element">
                  <a
                    [routerLink]="[
                      '/payments/resolution-center/dispute-details/',
                      element.id
                    ]"
                  >
                    {{ element.id }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="disputeDate">
                <th mat-header-cell *matHeaderCellDef>Dispute Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.disputeDate | date : "yyyy-MM-dd:HH:mm:ss" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="merchantId">
                <th mat-header-cell *matHeaderCellDef>Merchant ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.merchantId }}
                </td>
              </ng-container>

              <ng-container matColumnDef="merchantName">
                <th mat-header-cell *matHeaderCellDef>Merchant Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.merchantName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" class="centered-cell">
                  <app-dispute-status-card [text]="element.status" />
                </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>country</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.country }}
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amount }}
                </td>
              </ng-container>

              <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef>Currency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.currency }}
                </td>
              </ng-container>

              <ng-container matColumnDef="transactionId">
                <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.transactionId }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </app-card>
      </div>
    </ngx-simplebar>
    <div style="max-width: 70%">
      <mat-paginator
        [pageSizeOptions]="[5, 10]"
        [pageSize]="pageSizeOnGoing"
        [length]="selector?.totalElements"
        (page)="onPageChangeOnGoing($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>

  <div>
    <h3>Finished - Last 30 Days</h3>

    <ngx-simplebar [options]="{ autoHide: false }" style="overflow-y: hidden">
      <div class="row">
        <app-card [cardTitle]="''" style="margin-right: 16px !important">
          <div>
            <table
              mat-table
              [dataSource]="dataSourceFinished"
              class="transactions-table"
              cdk-virtual-scroll
              [loader]="isLoading"
            >
              <ng-container matColumnDef="disputeId">
                <th mat-header-cell *matHeaderCellDef>Dispute ID</th>
                <td mat-cell *matCellDef="let element">
                  <a
                    [routerLink]="[
                      '/payments/resolution-center/dispute-details/',
                      element.id
                    ]"
                  >
                    {{ element.id }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="disputeDate">
                <th mat-header-cell *matHeaderCellDef>Dispute Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.disputeDate | date : "yyyy-MM-dd:HH:mm:ss" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="merchantId">
                <th mat-header-cell *matHeaderCellDef>Merchant ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.merchantId }}
                </td>
              </ng-container>

              <ng-container matColumnDef="merchantName">
                <th mat-header-cell *matHeaderCellDef>Merchant Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.merchantName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" class="centered-cell">
                  <app-dispute-status-card [text]="element.status" />
                </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>country</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.country }}
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amount }}
                </td>
              </ng-container>

              <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef>Currency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.currency }}
                </td>
              </ng-container>

              <ng-container matColumnDef="transactionId">
                <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.transactionId }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </app-card>
      </div>
    </ngx-simplebar>
    <div style="max-width: 70%">
      <mat-paginator
        [pageSizeOptions]="[5, 10]"
        [pageSize]="pageSizeAttention"
        [length]="selector?.totalElements"
        (page)="onPageChangeFinished($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
