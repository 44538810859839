import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatThousand',
})
export class FormatThousandPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      const rawValue = value.toString();

      const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return `${formattedValue}`;
    }
    return '';
  }
}
