<div class="mt-5">
  <div class="row" style="width: 50%">
    <app-card cardTitle="Amount Validation">
      <h2 [loader]="isLoading">
        {{ selector | formatThousand }}
      </h2>

      <div class="description-line">
        <p>{{ data.description }}</p>
      </div>
    </app-card>
    <app-card cardTitle="Amount of Valid Document">
      <h2 [loader]="isLoading">
        {{ data.numbers | formatThousand }}
      </h2>

      <div class="description-line">
        <p>{{ data.description }}</p>
      </div>
    </app-card>
  </div>

  <div class="row">
    <div class="mt-5">
      <app-fieldset tittle="Filters">
        <mat-form-field appearance="outline">
          <mat-label>Date Interval</mat-label>
          <mat-date-range-input [rangePicker]="requestDate">
            <input
              matStartDate
              placeholder="Start Request date"
              [(ngModel)]="startDateValue"
            />
            <input
              matEndDate
              placeholder="End Request date"
              [(ngModel)]="endDateValue"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="requestDate"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #requestDate></mat-date-range-picker>
        </mat-form-field>

        <button mat-flat-button color="primary" (click)="filterTable()">
          Filter
        </button>
      </app-fieldset>

      <div class="row">
        <app-card [cardTitle]="''">
          <table mat-table [dataSource]="dataSource" class="transactions-table">
            <ng-container matColumnDef="paymentId">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.status }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="merchantFromId">
              <th mat-header-cell *matHeaderCellDef>Document</th>
              <td mat-cell *matCellDef="let element">
                {{ element.document }}
              </td>
            </ng-container>

            <ng-container matColumnDef="paymentMethod">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Channel</th>
              <td mat-cell *matCellDef="let element">
                {{ element.channel }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </app-card>
      </div>
      <div style="max-width: 70%">
        <mat-paginator
          [pageSizeOptions]="[10, 20]"
          [pageSize]="pageSize"
          [length]="selector"
          (page)="onPageChange($event)"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
