import { Component, ViewChild } from '@angular/core';
import { PaymentsService } from '../../../services/payments/payments.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-declines',
  templateUrl: './declines.component.html',
  styleUrls: ['./declines.component.scss'],
})
export class DeclinesComponent {
  @ViewChild('startDate') startDatePicker!: MatDatepicker<Date>;
  constructor(private paymentsService: PaymentsService) {}

  public rows: any = [];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public search: string | undefined = undefined;
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public startDateValue: string | undefined = undefined;
  public endDateValue: string | undefined = undefined;
  public isLoading: boolean = false;
  public filterParams = {};
  public filterParamsCsv = {};

  public displayedColumns: string[] = [
    'paymentId',
    'reference',
    'paymentMethod',
    'amount',
    'currencyCode',
    'createdAt',
    'cancellationDetail',
    'status',
    'errorCode',
  ];
  public dataSource = [];

  ngOnInit(): void {
    this.filterTable();
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public filterTable(page: number = 0) {
    this.isLoading = true;

    this.filterParamsCsv = {
      reportURL: 'declinesReportCsv',
      start: this.startDateValue,
      end: this.endDateValue,
      search: this.search,
      pageSize: this.pageSize,
      page: page,
    };

    this.dataSource = [];
    this.page = page;
    this.paymentsService
      .postReportDateFilter(
        'declinesReport',
        this.startDateValue,
        this.endDateValue,
        this.search,
        this.pageSize,
        page
      )
      .subscribe(({ content, totalElements }) => {
        this.dataSource = content;
        this.selector = totalElements;
        this.isLoading = false;
      });
  }
}
