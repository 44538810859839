<div class="row">
  <canvas
    baseChart
    class="chart"
    [data]="chartDataSet"
    [options]="barChartOptions"
    [type]="barChartType"
  >
  </canvas>
  <app-card [cardTitle]="''" [noTitle]="true">
    <div [loader]="isLoading">
      <div class="description-line">
        <h3>Total revenues in period</h3>
        <app-percentage-variation-card
          [value]="totalRevenue.fluctuation"
        ></app-percentage-variation-card>
      </div>

      <h2>{{ totalRevenue.amount | formatCurrency : currency }}</h2>

      <div class="description-line">
        <p>Total transactions in period</p>

        <app-percentage-variation-card
          [value]="totalRevenue.transactionFluctuation"
        ></app-percentage-variation-card>
      </div>

      <h2>{{ totalRevenue.count }}</h2>

      <div class="description-line">
        <p>ASP in period</p>

        <app-percentage-variation-card
          [value]="totalRevenue.aspFluctuation"
        ></app-percentage-variation-card>
      </div>

      <h2>{{ totalRevenue.avgticket | formatCurrency : currency }}</h2>
    </div>
  </app-card>
</div>
