<div [class]="theme">
  <div class="container background-login">
    <div style="position: relative" class="logo-image">
      <img [src]="logoPath" alt="Logo Epag" [width]="logoWidth" height="auto" />
    </div>
    <div class="card-login">
      <h2 class="white--text"><b>Login</b></h2>
      <form
        class="mt-5"
        role="form"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-group mt-3">
          <input
            formControlName="username"
            type="text"
            class="input"
            placeholder="E-mail"
            [class.input-invalid]="
              loginForm.controls['username'].invalid &&
              (loginForm.controls['username'].dirty ||
                loginForm.controls['username'].touched)
            "
          />
        </div>
        <div
          *ngIf="
            loginForm.controls['username'].invalid &&
            (loginForm.controls['username'].dirty ||
              loginForm.controls['username'].touched)
          "
          class="error-text ml-3"
        >
          *E-mail is required.
        </div>

        <div class="form-group mt-3">
          <input
            formControlName="password"
            type="password"
            class="input"
            placeholder="Password"
            [class.input-invalid]="
              loginForm.controls['password'].invalid &&
              (loginForm.controls['password'].dirty ||
                loginForm.controls['password'].touched)
            "
          />
        </div>

        <div
          *ngIf="
            loginForm.controls['password'].hasError('required') &&
            (loginForm.controls['password'].dirty ||
              loginForm.controls['password'].touched)
          "
          class="error-text ml-3"
        >
          *Password is required.
        </div>

        <div
          *ngIf="
            loginForm.controls['password'].hasError('minlength') &&
            (loginForm.controls['password'].dirty ||
              loginForm.controls['password'].touched)
          "
          class="error-text ml-3"
        >
          *Password must be at least 6 characters long.
        </div>

        <div
          *ngIf="
            loginForm.controls['captcha'].hasError('required') &&
            (loginForm.controls['captcha'].dirty ||
              loginForm.controls['captcha'].touched)
          "
          class="error-text ml-3"
        >
          *Captcha is required.
        </div>

        <div class="mt-2 error-text" *ngIf="hasError">
          {{ authError }}
        </div>

        <div class="mt-2">
          <ngx-recaptcha2
            #captchaElem
            [siteKey]="siteKey"
            (success)="handleSuccess($event)"
          >
          </ngx-recaptcha2>
        </div>

        <button
          type="submit"
          style="width: 100%"
          class="mb-5 mt-3 btn btn-login"
          (onClick)="onSubmit()"
        >
          <b>Login</b>
        </button>
      </form>
    </div>
  </div>
</div>
