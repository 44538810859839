import { Component } from '@angular/core';
import { SideBarService } from '../../services/side-bar/side-bar.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent {
  constructor(public sidebarService: SideBarService) {}
}
