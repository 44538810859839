import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-last-days-card',
  templateUrl: './last-days-card.component.html',
  styleUrls: ['./last-days-card.component.scss'],
})
export class LastDaysCardComponent implements OnInit, OnChanges {
  @Input() value = {
    id: '30Sales',
    amount: 0,
  };
  public style: Object = {};
  public days: number = 30;

  ngOnInit(): void {
    if (this.value.id === '07Sales') {
      this.days = 7;
    }
  }
  ngOnChanges(): void {
    if (this.value.amount > 0) {
      this.style = { background: '#1B98E0' };
    } else if (this.value.amount < 0) {
      this.style = { background: 'red' };
    } else {
      this.style = { background: '#FA0' };
    }
  }
}
