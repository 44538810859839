import { Component, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private renderer: Renderer2,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    const title = 'epag-merchant-admin';
    const linkEl = this.document.querySelector("link[rel='icon']");
    const theme = environment.theme;
    this.renderer.addClass(this.document.documentElement, theme);

    if (linkEl) {
      this.titleService.setTitle('Epag Merchant Admin');
      if (theme === 'skin-epag') {
        this;
        this.renderer.setAttribute(
          linkEl,
          'href',
          'assets/favicon-epag.ico?v=' + new Date().getTime()
        );
      } else if (theme === 'skin-letpay') {
        this.titleService.setTitle('Letpay Merchant Admin');
        this.renderer.setAttribute(
          linkEl,
          'href',
          'assets/favicon-letpay.ico?v=' + new Date().getTime()
        );
      }
    }
  }
}
