import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { RefundsService } from 'src/app/services/refund/refunds.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-refunds-report',
  templateUrl: './refunds-report.component.html',
  styleUrls: ['./refunds-report.component.scss'],
})
export class RefundsReportComponent {
  public rows: any = [];
  public confirmationStartDate: string | undefined = undefined;
  public confirmationEndDate: string | undefined = undefined;
  public requestStartDate: string | undefined = undefined;
  public requestEndDate: string | undefined = undefined;
  public selectedStatus: string = '';
  public statusList: Array<string> = [];
  public selector: any = {};
  public search: string = '';
  public pageSize: number = 10;
  public page: number = 0;
  public disabledWhileRequest: boolean = false;
  public isLoading: boolean = false;
  public filterParamsCsv = {};
  private searchParam: string = '';

  public displayedColumns: string[] = [
    'refundID',
    'amount',
    'currency',
    'refundsStatus',
    'transactionId',
    'refundDate',
    'country',
  ];
  public dataSource = [];
  public filterParams = {};

  constructor(
    private refundsService: RefundsService,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.getStatuses();
    this.filterTable();
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public getStatuses() {
    this.refundsService.getRefundStatuses().subscribe(({ content }) => {
      this.statusList = content;
    });
  }

  public getCSV(separator: string) {
    this.refundsService
      .getReportsCsv(
        this.paymentsService.formatDate(this.requestStartDate || ''),
        this.paymentsService.formatDate(this.requestEndDate || ''),
        this.paymentsService.formatDate(this.confirmationStartDate || ''),
        this.paymentsService.formatDate(this.confirmationEndDate || ''),
        this.searchParam,
        this.selectedStatus !== 'ALL' ? this.selectedStatus : '',
        separator
      )
      .subscribe(
        (csvData: string) => {
          const blob = new Blob([csvData], { type: 'text/csv' });
          let realm = environment.theme.split('-')[1];
          saveAs(blob, 'Refunds' ? `${realm}_${'Refunds'}.csv` : 'data.csv');
          this.disabledWhileRequest = false;
        },
        (error) => {
          console.error('Error downloading CSV:', error);
          alert('Error downloading CSV file');
          this.disabledWhileRequest = false;
        }
      );
  }

  public filterTable(page: number = 0) {
    this.isLoading = true;
    this.page = page;
    this.dataSource = [];
    this.page = page;
    this.dataSource = [];
    this.page = page;

    this.refundsService
      .getReports(
        this.paymentsService.formatDate(this.requestStartDate || ''),
        this.paymentsService.formatDate(this.requestEndDate || ''),
        this.paymentsService.formatDate(this.confirmationStartDate || ''),
        this.paymentsService.formatDate(this.confirmationEndDate || ''),
        this.search,
        this.selectedStatus !== 'ALL' ? this.selectedStatus : '',
        page,
        this.pageSize
      )
      .subscribe(({ content, totalElements }) => {
        this.dataSource = content;
        this.selector.totalElements = totalElements;
        this.isLoading = false;
      });
  }
}
