<table>
  <thead>
    <tr>
      <th>Currency</th>
      <th>Value (U$)</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let currency of currencies">
      <td>
        <mat-radio-group [(ngModel)]="selectedCurrency" name="currencyGroup">
          <mat-radio-button [value]="currency.asset">{{
            currency.asset
          }}</mat-radio-button>
        </mat-radio-group>
      </td>
      <td>
        {{ currency.amount + " " + currency.asset }}
      </td>
    </tr>
  </tbody>
</table>

<div class="buttons">
  <button (click)="close()" mat-stroked-button>Cancel</button>
  <button (click)="onCurrencyChange()" mat-flat-button color="primary">
    Change
  </button>
</div>
