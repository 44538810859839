import { Component } from '@angular/core';
import { PaymentsService } from '../../../services/payments/payments.service';
import { IdValidationService } from 'src/app/services/id-validation/id-validation.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-id-service-statements',
  templateUrl: './id-service-statements.component.html',
  styleUrls: ['./id-service-statements.component.scss'],
})
export class IdServiceStatementsComponent {
  constructor(private paymentsService: PaymentsService, private idValidationService: IdValidationService) {
    this.startDateValue = this.paymentsService.getOneMonthAgoDate();
    this.endDateValue = this.paymentsService.getCurrentDate()
  }

  public rows: any = [];

  public displayedColumns: string[] = [
    'statementId',
    'contractId',
    'creationDate',
    'transactions',
  
  ];
  public dataSource = [];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public startDateValue: string | undefined = undefined;
  public endDateValue: string | undefined = undefined;
  public search: string | undefined = undefined;
  public filterParams = {};
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public isLoading: boolean = true;


  ngOnInit(): void {
    this.isLoading = true;
    this.filterTable();

  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public filterTable(page: number = 0, searchParam: string = '') {
    this.isLoading = true;
    this.filterParams = {
      start: this.startDateValue,
      end: this.endDateValue,
      search: searchParam,
      pageSize: this.pageSize,
      page: page,
    };

    this.dataSource = [];
    this.page = page;

    this.idValidationService
      .getIdValidationStatements(
        this.paymentsService.formatDate(this.startDateValue || ''),
        this.paymentsService.formatDate(this.endDateValue || ''),
        searchParam,
        page,
        this.pageSize
      )
      .subscribe(({ content, totalElements }) => {
        this.dataSource = content;
        this.selector = totalElements;
        this.isLoading = false;
      });

  }
}
