import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { tap } from 'rxjs';

export interface settlements {
  bankName: string;
  currency: string;
  date: string;
  proportionalValue: number;
  status: string;
}

@Component({
  selector: 'app-settlements-table',
  templateUrl: './settlements-table.component.html',
  styleUrls: ['./settlements-table.component.scss'],
})
export class SettlementsTableComponent implements OnInit {
  public rows: any = [];
  public displayedColumns: string[] = [
    'status',
    'creationDate',
    'paymentDate',
    'bank',
    'approxAmount',
  ];
  public dataSource: settlements[] = [];
  public isLoading: boolean = false;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.isLoading = true;
    this.dashboardService
      .getSettlements()
      .pipe(
        tap(({ result }) => {
          this.dataSource = result;
        })
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
