<app-card [cardTitle]="'Unblock'" [hasDivider]="true">
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Select one field to block</mat-label>
      <mat-select disabled [(ngModel)]="blockedContract.rule_type">
        <mat-option *ngFor="let option of options" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Blocked At</mat-label>
      <input
        matInput
        disabled
        placeholder="Blocked at:"
        [(ngModel)]="blockedContract.created_at"
      />
    </mat-form-field>
  </div>

  <!-- <div class="row mt-5">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Reason to unblock</mat-label>
      <input
        matInput
        placeholder="Describe the reason to unblock"
        [(ngModel)]="unblockReason"
      />
    </mat-form-field>
  </div> -->

  <div class="row mt-5">
    <button class="white-btn" (click)="close()">Cancel</button>
    <button class="send-btn ml-5" (click)="unblockContract()">Unblock</button>
  </div>
</app-card>
