import { Component } from '@angular/core';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent {
  public lineChartData: {
    data: number[];
    label: string;
    borderColor: string;
  }[] = [
    {
      data: [65, 59, 80, 70, 83, 95, 99],
      label: 'Series A',
      borderColor: '#fa0',
    },
  ];
  public lineChartLabels: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];
  public lineChartOptions: any = {
    responsive: true,

    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },

      interaction: {
        intersect: false,
      },
    },
  };
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];
}
