import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() cardTitle: string = 'Default Title';
  @Input() biggerTitle: boolean = false;
  @Input() hasDivider: boolean = false;
  @Input() lastDaysCard = { id: 'none', amount: 0 };
  @Input() centerTitle: boolean = false;
  @Input() cardColor: string = '#fff';
  @Input() noTitle: boolean = false;
}
