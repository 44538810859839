import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin, { Context } from 'chartjs-plugin-datalabels';
import { environment } from '../../../environments/environment';

export interface DataType {
  gross: number;
  method?: string;
  contract?: string;
}
function createCenterTextPlugin(title: string) {
  return {
    id: 'centerTextPlugin',
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      const lines = title.split('<br>');
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;

      const baseCenterY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      const totalHeight = lines.length * 20;
      let startY = baseCenterY - totalHeight / 2 + 10;

      ctx.font = '18px Arial';
      ctx.fillStyle = '#999';

      for (let line of lines) {
        ctx.fillText(line, centerX, startY);
        startY += 20;
      }
      ctx.restore();
    },
  };
}

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent {
  @Input() chartData: DataType[] = [
    {
      gross: 0,
    },
  ];
  @Input() chartTitle: string = '';
  @Input() legendPosition:
    | 'chartArea'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'center' = 'right';

  @ViewChild(BaseChartDirective, { static: true }) chart:
    | BaseChartDirective
    | undefined;

  public chartType: ChartType = 'doughnut';
  public chartDataSet: ChartData<'doughnut'> = {
    labels: [],
    datasets: [],
  };
  public chartLabels = [];
  public donutChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: this.legendPosition,
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          return `${value.toString()}%`;
        },
        font: {
          size: 14,
          weight: 'bolder',
        },
        color: '#fff',
      },
    },
  };

  public donutChartPlugins = [
    DataLabelsPlugin,
    createCenterTextPlugin(this.chartTitle),
  ];

  private epagColorSet = ['#295DA0', '#6DCFF6', '#00A7E1'];
  private letpayColorSet = ['#FFC06E', '#F4A641', '#E45859'];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartTitle']) {
      this.donutChartPlugins = [
        DataLabelsPlugin,
        createCenterTextPlugin(this.chartTitle),
      ];
    }

    if (changes['legendPosition'] && this.donutChartOptions?.plugins?.legend) {
      this.donutChartOptions.plugins.legend.position = this.legendPosition;
      this.chart?.update();
    }

    if (this.chartData) {
      this.chartData.forEach((element: DataType) => {
        const label = element.method ?? element.contract;
        if (label !== undefined && this.chartDataSet.labels) {
          this.chartDataSet.labels.push(label);
        }
      });

      this.chartDataSet.datasets = [
        {
          data: this.calculateGrossPercentages(this.chartData),
          backgroundColor:
            environment.theme === 'skin-letpay'
              ? this.letpayColorSet
              : this.epagColorSet,
        },
      ];

      this.chart?.update();
    }
  }

  private calculateGrossPercentages(data: DataType[]): number[] {
    let totalGross = data.reduce((total, item) => total + item.gross, 0);

    return data.map((item) =>
      Number(((item.gross / totalGross) * 100).toFixed(0))
    );
  }

  private drawCenterText(chartInstance: any, text: string) {
    const ctx = chartInstance.ctx;
    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const centerX =
      (chartInstance.chartArea.left + chartInstance.chartArea.right) / 2;
    const centerY =
      (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;
    ctx.font = '20px Arial';
    ctx.fillStyle = 'black';
    ctx.fillText(text, centerX, centerY);
    ctx.restore();
  }
}
