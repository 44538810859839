import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentsService } from '../payments/payments.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RefundsService {
  private baseUrl = `${environment.apiURL}/report`;
  private refundUrl = `${environment.apiURL}/refund`;
  private cacheAPI = sessionStorage;

  constructor(
    private http: HttpClient,
    private paymentsService: PaymentsService
  ) {}

  public getReports(
    startRequestDate: string | undefined,
    endRequestDate: string | undefined,
    startConfirmationDate: string | undefined,
    endConfirmationDate: string | undefined,
    search: string = '',
    status: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    const filter = {
      endRequestDate: endRequestDate,
      startRequestDate: startRequestDate,
      endConfirmationDate: endConfirmationDate,
      startConfirmationDate: startConfirmationDate,
      search: search,
      status: status,
      pageSize: pageSize,
      page: page,
    };

    let queryParams = new HttpParams();
    const merchantId = JSON.parse(
      this.cacheAPI.getItem('user') || '[]'
    ).merchant_id;

    queryParams = filter.page
      ? queryParams.append('page', filter.page)
      : queryParams;

    queryParams = filter.search
      ? queryParams.append('search', filter.search)
      : queryParams;

    queryParams = filter.pageSize
      ? queryParams.append('size', filter.pageSize)
      : queryParams;

    queryParams = filter.startRequestDate
      ? queryParams.append('startRequestDate', filter.startRequestDate)
      : queryParams;

    queryParams = filter.startConfirmationDate
      ? queryParams.append(
          'startConfirmationDate',
          filter.startConfirmationDate
        )
      : queryParams;

    queryParams = filter.endRequestDate
      ? queryParams.append('endRequestDate', filter.endRequestDate)
      : queryParams;

    queryParams = filter.endConfirmationDate
      ? queryParams.append('endConfirmationDate', filter.endConfirmationDate)
      : queryParams;

    queryParams = filter.status
      ? queryParams.append('status', filter.status)
      : queryParams;

    queryParams = queryParams.append('merchantId', merchantId);

    const url = `${this.baseUrl}/refunds`;

    return this.http.get<any>(url, { params: queryParams });
  }

  public getReportsCsv(
    startRequestDate: string | undefined,
    endRequestDate: string | undefined,
    startConfirmationDate: string | undefined,
    endConfirmationDate: string | undefined,
    search: string,
    status: string,
    separatorType: string = 'COMMA'
  ): Observable<any> {
    const url = `${this.baseUrl}/refunds/csv`;

    const filter = {
      endRequestDate: endRequestDate,
      startRequestDate: startRequestDate,
      endConfirmationDate: endConfirmationDate,
      startConfirmationDate: startConfirmationDate,
      search: search,
      status: status,
      pageSize: 9999,
      page: 0,
    };

    let queryParams = new HttpParams();
    const merchantId = JSON.parse(
      this.cacheAPI.getItem('user') || '[]'
    ).merchant_id;

    queryParams = filter.search
      ? queryParams.append('search', filter.search)
      : queryParams;

    queryParams = filter.page
      ? queryParams.append('page', filter.page)
      : queryParams;

    queryParams = filter.pageSize
      ? queryParams.append('size', filter.pageSize)
      : queryParams;

    queryParams = filter.startRequestDate
      ? queryParams.append('startRequestDate', filter.startRequestDate)
      : queryParams;

    queryParams = filter.startConfirmationDate
      ? queryParams.append(
          'startConfirmationDate',
          filter.startConfirmationDate
        )
      : queryParams;

    queryParams = filter.endRequestDate
      ? queryParams.append('endRequestDate', filter.endRequestDate)
      : queryParams;

    queryParams = filter.endConfirmationDate
      ? queryParams.append('endConfirmationDate', filter.endConfirmationDate)
      : queryParams;

    queryParams = filter.status
      ? queryParams.append('status', filter.status)
      : queryParams;

    queryParams = queryParams.append('merchantId', merchantId);
    queryParams = queryParams.append('separatorType', separatorType);

    return this.http.get(url, { params: queryParams, responseType: 'text' });
  }

  public getRefundStatuses(): Observable<any> {
    const url = `${this.refundUrl}/statuses`;
    return this.http.get(url);
  }
}
