<div class="header">
  <h1>{{ (subMenuName || menuName) | splitCamelCase }}</h1>
  <div class="breadcrumb">
    <b>{{ appName | splitCamelCase }}</b>
    <a [href]="url.split('/')[1]" *ngIf="menuName">
      <hr />
      <b>{{ menuName | splitCamelCase }}</b>
    </a>
    <a [href]="'/' + url.split('/')[1] + '/' + url.split('/')[2]" *ngIf="subMenuName">
      <hr />
      <b>{{ subMenuName | splitCamelCase }}</b>
    </a>
  </div>
</div>
