import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CurrencyModel } from 'src/app/models/currency.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private cacheAPI = sessionStorage;
  private url = environment.apiURL + '/rate/';
  values: CurrencyModel[] | null = null;
  assets: CurrencyModel[] | null = null;
  current = 'USD';

  constructor(private http: HttpClient) {}

  public getCurrencies(): void {
    this.http
      .get(this.url)
      .pipe(
        map((response: any) => {
          const values: CurrencyModel[] = [];
          const assets: CurrencyModel[] = [];

          response.forEach((obj: any) => {
            let symbol;

            switch (obj.counterAsset) {
              case 'USD':
                symbol = '$';
                break;
              case 'BRL':
                symbol = 'R$';
                break;
              case 'EUR':
                symbol = '€';
                break;
              default:
                symbol = '$';
                break;
            }

            const value: CurrencyModel = {
              asset: obj.counterAsset,
              amount: obj.amount,
            };

            const asset: CurrencyModel = {
              asset: obj.counterAsset,
              amount: obj.amount,
              symbol: symbol,
            };

            values.push(value);
            assets.push(asset);
          });

          this.assets = assets;
          this.cacheAPI.setItem('_cache_values', JSON.stringify(values));
          this.cacheAPI.setItem('_cache_assets', JSON.stringify(assets));
        })
      )
      .subscribe();
  }

  public getAssets(): CurrencyModel[] | undefined {
    let assets = JSON.parse(this.cacheAPI.getItem('_cache_assets') || 'null');

    if (!assets) {
      // this.get();
      this.getCurrencies();
      assets = JSON.parse(this.cacheAPI.getItem('_cache_assets') || 'null');
    }

    return assets;
  }

  public change(value: number, format: boolean): string {
    const values = JSON.parse(this.cacheAPI.getItem('_cache_values') || 'null');
    const assets = JSON.parse(this.cacheAPI.getItem('_cache_assets') || 'null');

    if (!values && !assets) {
      return '';
    }
    let amount: number = 0;
    values.forEach((obj: CurrencyModel) => {
      if (obj.asset === this.current) {
        amount = obj.amount;
      }
    });

    let newValue: number = value * (amount || 0);
    let formattedNewValue: string = newValue.toString();

    if (format) {
      formattedNewValue = `${newValue.toFixed(2)} ${this.current}`;
    }

    return formattedNewValue;
  }

  public changeBalances(value: number, currency: string): number {
    const values = JSON.parse(this.cacheAPI.getItem('_cache_values') || 'null');
    const assets = JSON.parse(this.cacheAPI.getItem('_cache_assets') || 'null');

    if (values && assets) {
      return 0;
    }
    if (currency === this.current) {
      return value;
    }

    let newValue = 0;
    let currentAmount = 0;
    let currencyAmount = 0;

    values.forEach((obj: CurrencyModel) => {
      if (obj.asset === currency) {
        if (this.current === 'USD') {
          newValue = value / obj.amount;
        } else {
          currencyAmount = obj.amount;
        }
      } else if (obj.asset === this.current) {
        currentAmount = obj.amount;
      }
    });

    if (newValue === 0) {
      newValue = value / currencyAmount / (1 / currentAmount);
    }

    return newValue;
  }
}
