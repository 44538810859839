<app-card [cardTitle]="'New Block'" [hasDivider]="true">
  <div class="row mb-5 mt-5 row-no-margin">
    <div class="column half" style="align-items: start">
      <mat-checkbox
        [(ngModel)]="selectedAllContracts"
        style="margin: 2px !important"
      >
        All Contracts
      </mat-checkbox>
    </div>
  </div>

  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Select contract to block</mat-label>
      <mat-select
        [disabled]="selectedAllContracts"
        [(value)]="selectedProjects"
      >
        <mat-option *ngFor="let item of projectsList" [value]="item.id">{{
          item.id
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Select one field to block</mat-label>
      <mat-select [(ngModel)]="ruleType">
        <mat-option *ngFor="let option of options" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Value</mat-label>
      <input matInput placeholder="Input a value" [(ngModel)]="rule" />
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Description</mat-label>
      <input
        matInput
        placeholder="Describe the reason for the block"
        [(ngModel)]="ruleDescription"
      />
    </mat-form-field>
  </div>

  <div class="row mb-5 row-no-margin">
    <h3><b>Blocked Payment Methods *</b></h3>
  </div>
  <div class="row row-no-margin">
    <div class="column half" style="align-items: start">
      <mat-checkbox
        *ngFor="let method of getFirstHalfOfPaymentMethods()"
        [(ngModel)]="selectedPaymentMethods[method]"
        style="margin: 2px !important"
      >
        {{ getPaymentMethodName(method) }}
      </mat-checkbox>
    </div>

    <div class="column half" style="align-items: start">
      <mat-checkbox
        *ngFor="let method of getSecondHalfOfPaymentMethods()"
        [(ngModel)]="selectedPaymentMethods[method]"
        style="margin: 2px !important"
      >
        {{ getPaymentMethodName(method) }}
      </mat-checkbox>
    </div>
  </div>

  <div class="row mt-5" style="color: red">
    <p>* If no payment method is selected all methods are blocked</p>
  </div>

  <div class="row mt-5">
    <button class="white-btn" (click)="close()">Cancel</button>
    <button class="block-btn ml-5" (click)="createNewBlock()">Block</button>
  </div>
</app-card>
