import { Component, ViewChild, OnInit } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { PaymentsService } from '../../../services/payments/payments.service';
import { FormatCurrencyPipe } from '../../../pipes/format-currency.pipe';
@Component({
  selector: 'app-revenues',
  templateUrl: './revenues.component.html',
  styleUrls: ['./revenues.component.scss'],
})
export class RevenuesComponent implements OnInit {
  @ViewChild(BaseChartDirective, { static: true }) chart:
    | BaseChartDirective
    | undefined;

  public selectedOptions = {
    creditCard: true,
    pix: true,
    boletoBancario: true,
  };

  public lineChartLabels = ['Janeiro', 'Fevereiro', 'Março', 'Abril'];
  public lineChartData: ChartDataset[] = [
    { data: [0], label: 'Credit Card', fill: true },
    { data: [0], label: 'Pix', fill: true },
    { data: [0], label: 'Boleto Bancário', fill: true },
  ];

  public originalLineChartData: ChartDataset[] = [
    { data: [0], label: 'Credit Card', fill: true },
    { data: [0], label: 'Pix', fill: true },
    { data: [0], label: 'Boleto Bancário', fill: true },
  ];

  public startDateValue: string | undefined = undefined;
  public endDateValue: string | undefined = undefined;

  public lineChartOptions: ChartOptions = {
    responsive: true,

    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        stacked: true,
        min: 0,
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || '';

            const value = this.formatCurrencyPipe.transform(context.parsed.y);

            return `${label}: ${this.currency} ${value}`;
          },
          footer: (tooltipItems: any) => {
            if (tooltipItems.length > 0) {
              let total = 0;

              for (const item of tooltipItems) {
                total += item.raw;
              }

              const formattedTotal = this.formatCurrencyPipe.transform(total);
              return `Total: ${this.currency} ${formattedTotal}`;
            }
            return '';
          },
        },
      },
    },
  };

  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  private formatCurrencyPipe = new FormatCurrencyPipe();
  private currency: string = '';

  constructor(private paymentsService: PaymentsService) {}

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  public toggleAll() {
    this.selectedOptions.creditCard = true;
    this.selectedOptions.pix = true;
    this.selectedOptions.boletoBancario = true;

    this.updateChart();
  }

  public updateChart() {
    Promise.all([
      this.selectedOptions.creditCard
        ? this.updateRevenuesReport('Credit Card')
        : Promise.resolve(undefined),
      this.selectedOptions.pix
        ? this.updateRevenuesReport('Pix')
        : Promise.resolve(undefined),
      this.selectedOptions.boletoBancario
        ? this.updateRevenuesReport('Boleto Bancário')
        : Promise.resolve(undefined),
    ])
      .then(([creditCardData, pixData, boletoData]) => {
        this.lineChartData = [
          this.selectedOptions.creditCard
            ? this.originalLineChartData[0]
            : undefined,
          this.selectedOptions.pix ? this.originalLineChartData[1] : undefined,
          this.selectedOptions.boletoBancario
            ? this.originalLineChartData[2]
            : undefined,
        ].filter(Boolean) as ChartDataset[];

        if (creditCardData) {
          this.currency = creditCardData[0].asset;
        }
        this.lineChartData = [
          creditCardData
            ? {
                data: creditCardData.map((item) => item.amount),
                label: 'Credit Card',
                fill: true,
                borderColor: '#FA0',
              }
            : undefined,
          pixData
            ? {
                data: pixData.map((item) => item.amount),
                label: 'Pix',
                fill: true,
                borderColor: '#EC4727',
              }
            : undefined,
          boletoData
            ? {
                data: boletoData.map((item) => item.amount),
                label: 'Boleto Bancário',
                fill: true,
                borderColor: '#00D68F',
              }
            : undefined,
        ].filter(Boolean) as ChartDataset[];

        const firstDataSet = creditCardData || pixData || boletoData;
        if (firstDataSet) {
          this.lineChartLabels = this.getLineChartLabels(firstDataSet);
        }

        this.chart?.update();
      })
      .catch((error) => console.error(error));
  }

  public areAllSelected(): boolean {
    return (
      this.selectedOptions.creditCard &&
      this.selectedOptions.pix &&
      this.selectedOptions.boletoBancario
    );
  }

  private updateRevenuesReport(label: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const dataset = this.originalLineChartData.find(
        (item) => item.label === label
      );

      if (dataset) {
        this.paymentsService
          .postRevenuesReport(this.endDateValue, this.startDateValue)
          .subscribe(({ result }) => {
            const name = label
              .replace('Bancário', '')
              .replace(' ', '')
              .toUpperCase();
            const resultItem = result.find((item: any) => item.name === name);

            if (resultItem && resultItem.ocurrences) {
              dataset.data = resultItem.ocurrences.map(
                (ocurrence: any) => ocurrence.amount
              );
              resolve(resultItem.ocurrences);
            } else {
              reject('No matching result found');
            }
          });
      } else {
        reject('No matching dataset found');
      }
    });
  }

  private getLineChartLabels(data: any[]): string[] {
    return data.map((entry) => `${entry.day}/${entry.month}/${entry.year}`);
  }

  private getTotalForIndex(index: number, data: ChartDataset[]): number {
    let total = 0;
    data.forEach((dataset) => {
      const value = dataset.data[index];
      if (typeof value === 'number') {
        total += value;
      }
    });
    return total;
  }
}
