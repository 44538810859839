import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayoutsService {
  public baseUrl: string = environment.apiURL + '/payout';

  constructor(private http: HttpClient) {}

  public getPayouts(
    referenceId: string,
    payoutStartDate: string,
    payoutEndDate: string,
    status: string,
    pageSize: number = 10,
    page: number = 0
  ): Observable<any> {
    const url = `${this.baseUrl}`;

    let queryParams = new HttpParams();
    queryParams = referenceId
      ? queryParams.append('search', referenceId)
      : queryParams;
    queryParams = payoutStartDate
      ? queryParams.append('beginDate', payoutStartDate)
      : queryParams;
    queryParams = payoutEndDate
      ? queryParams.append('endDate', payoutEndDate)
      : queryParams;
    queryParams = status ? queryParams.append('status', status) : queryParams;
    queryParams = page ? queryParams.append('page', page) : queryParams;
    queryParams = pageSize ? queryParams.append('size', pageSize) : queryParams;

    return this.http.get(url, { params: queryParams });
  }

  public getPayoutsCSV(startDate: string, endDate: string, status?: string, separatorType?: string) {
    const url = `${this.baseUrl}/transactionsCsv`;

    let queryParams = new HttpParams();
    queryParams = startDate ?  queryParams.append('beginDate', startDate) : queryParams;

    queryParams = endDate ? queryParams.append('endDate', endDate): queryParams;

    queryParams = status ? queryParams.append('status', status) : queryParams;

    queryParams = separatorType ? queryParams.append('separatorType', separatorType) : queryParams;

    return this.http.get(url, { params: queryParams, responseType: 'text' });
  }

  public getPayoutStatuses() {
    const url = `${environment.apiURL}/payoutTransaction/statuses`;
    return this.http.get<any>(url);
  }

  public getPayoutsDetails(id: string) {
    const url = `${environment.apiURL}/transactionDetails/payout/${id}`;
    return this.http.get<any>(url);
  }

  public downloadReceipt(id: string) {
    const url = `${environment.apiURL}/payoutTransaction/${id}/receipt/`;

    return this.http.get<any>(url, {
      responseType: 'blob' as 'json'
    });
  }
}
