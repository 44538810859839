import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { CurrencyService } from '../../services/currency/currency.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public topPaymentSystems: any = {};
  public topContracts: any = {};

  constructor(
    private dashboardService: DashboardService,
    private currencyService: CurrencyService
  ) {}

  ngOnInit(): void {
    this.currencyService.getCurrencies();

    this.dashboardService.getTopPaymentSystems(30).subscribe(({ response }) => {
      this.topPaymentSystems = response;
    });

    this.dashboardService.getTopContracts(30).subscribe(({ response }) => {
      this.topContracts = response;
    });
  }
}
