<div class="chart-container mb-2">
  <canvas
    baseChart
    class="chart"
    [data]="chartDataSet"
    [labels]="chartLabels"
    [options]="donutChartOptions"
    [type]="chartType"
    [plugins]="donutChartPlugins"
  >
  </canvas>
</div>
