<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="requestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="startDateValue"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="endDateValue"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="requestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #requestDate></mat-date-range-picker>
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>

  <div class="row">
    <app-card [cardTitle]="''">
      <table mat-table [dataSource]="dataSource" class="transactions-table">
        <ng-container matColumnDef="statementId">
          <th mat-header-cell *matHeaderCellDef>Statement ID</th>
          <td mat-cell *matCellDef="let element">
            <div class="last-days-card">
              <a
                [routerLink]="['/id-validation/reports/statement-detail']"
                [queryParams]="{ statementId: element.id }"
                >{{ element.id }}</a
              >
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="contractId">
          <th mat-header-cell *matHeaderCellDef>Contract ID</th>
          <td mat-cell *matCellDef="let element">
            {{ element.contractId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>Creation Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.creationDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transactions">
          <th mat-header-cell *matHeaderCellDef>Requests</th>
          <td mat-cell *matCellDef="let element">
            {{ element.transactionCount }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
</div>
