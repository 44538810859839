import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth-service.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      req = this.addTokenToRequest(req, token);
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (
          event instanceof HttpResponse &&
          event.body &&
          event.body.refresh_token
        ) {
          this.authService.setToken(event.body.refresh_token);
        }
      }),
      catchError((error) => {
        if (error.status === 401) {
          const newToken = this.authService.getToken();

          if (newToken) {
            req = this.addTokenToRequest(req, newToken);
            // this.showTokenAlert();
            return next.handle(req);
          } else {
            this.showTokenAlert();
            return throwError('Failed to get token.');
          }
        } else {
          this.showAlert(error.status, error.error.message || error.statusText);
          return throwError(error);
        }
      })
    );
  }

  private showTokenAlert() {
    return Swal.fire({
      title: 'Error',
      text: 'Token Expired',
      icon: 'warning',
      confirmButtonText: 'Ok',
      reverseButtons: true,
    }).then((result) => {
      this.router.navigate(['']);
      return false;
    });
  }

  private showAlert(errorCode: number, errorDescription: string) {
    return Swal.fire({
      title: errorCode,
      text: errorDescription,
      icon: 'warning',
      confirmButtonText: 'Ok',
      reverseButtons: true,
    });
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'X-Auth-Token': token,
      },
    });
  }
}
