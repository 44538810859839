import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pending-status-card',
  templateUrl: './pending-status-card.component.html',
  styleUrls: ['./pending-status-card.component.scss'],
})
export class PendingStatusCardComponent {
  @Input() public status: string = 'pending';
  public style: Object = {};

  ngOnChanges(): void {
    this.status = this.status.toLowerCase();
    if (this.status === 'pending') {
      this.style = { background: '#D60040' };
    } else if (this.status === 'settled') {
      this.style = { background: '#00D68F' };
    } else {
      this.style = { background: '#016197' };
    }
  }
}
