import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../../services/dashboard/dashboard.service';
import { CurrencyService } from '../../../../services/currency/currency.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-indices',
  templateUrl: './indices.component.html',
  styleUrls: ['./indices.component.scss'],
})
export class IndicesComponent implements OnInit {
  public data: any[] = [
    {
      id: 'sales',
      period: '30Sales',
      title: 'Sales',
      description: 'Total Revenues',
      numbers: 0,
      fluctuation: 0,
    },
    {
      id: 'sales',
      period: '07Sales',
      title: 'Sales',
      description: 'Total Revenues',
      numbers: 0,
      fluctuation: 0,
    },
    {
      id: 'transactions',
      period: '30Sales',
      title: 'Nº Transactions',
      description: 'Transactions',
      numbers: 0,
      fluctuation: 0,
    },
    {
      id: 'asp',
      period: '30Sales',
      title: 'ASP',
      description: 'Avg. Selling Price',
      numbers: 0,
      fluctuation: 0,
    },
  ];

  public content: boolean = false;
  public currency: string = '';
  public isLoading: boolean = false;
  private cacheApi = sessionStorage;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.currency = JSON.parse(
      this.cacheApi.getItem('selectedCurrency') || '"USD"'
    );

    this.getIndicesValues();
  }

  private getIndicesValues() {
    this.isLoading = true;
    forkJoin({
      sales07: this.dashboardService.getIndices(7, '07Sales'),
      sales30: this.dashboardService.getIndices(30, '30Sales'),
    }).subscribe((results) => {
      this.formatIndices(results.sales07.totals, '07Sales');
      this.formatIndices(results.sales30.totals, '30Sales');
      this.isLoading = false;
    });
  }

  private formatIndices(totals: any, period: string) {
    const { amount, count, avgticket, previousValues } = totals;

    this.data.forEach((item) => {
      if (item.period === period) {
        if (item.id === 'sales') {
          item.numbers = amount;
          item.fluctuation = this.dashboardService.getFluctuation(
            previousValues.amount,
            item.numbers
          ).percentage;
        } else if (item.id === 'transactions') {
          item.numbers = count;
          item.fluctuation = this.dashboardService.getFluctuation(
            previousValues.count,
            item.numbers
          ).percentage;
        } else if (item.id === 'asp') {
          item.numbers = avgticket;
          item.fluctuation = this.dashboardService.getFluctuation(
            previousValues.avgticket,
            item.numbers
          ).percentage;
        }
      }
    });
  }
}
