import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'splitCamelCase' })
export class SplitCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const spaced = value
      .replace(/[-]/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .trim();

    const capitalized = spaced
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    return capitalized;
  }
}
