import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { DisputesService } from '../../services/disputes/disputes.service';
import { formatDate } from '../../utils/date-utils';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-disputes',
  templateUrl: './disputes.component.html',
  styleUrls: ['./disputes.component.scss'],
})
export class DisputesComponent implements OnInit {
  public startDate: Date | undefined = undefined;
  public endDate: Date | undefined = undefined;
  public rows: any = [];
  public selector: any = {};
  public pageSizeAttention: number = 5;
  public pageSizeDecision: number = 5;
  public pageSizeOnGoing: number = 5;
  public pageSizeFinished: number = 5;

  public page: number = 0;
  public isLoading: boolean = false;
  public dataSourceAttention = [];
  public dataSourceDecision = [];
  public dataSourceOnGoing = [];
  public dataSourceFinished = [];
  public filterParamsCsv = {};
  public displayedColumns: string[] = [
    'disputeId',
    'disputeDate',
    'status',
    'country',
    'amount',
    'currency',
    'transactionId',
  ];
  public searchParam: string = '';

  constructor(private disputeService: DisputesService) {}

  ngOnInit(): void {
    this.filterAllTables();
  }

  public filterAllTables(page: number = 0) {
    this.filterTableAttention(page);
    this.filterTableOnGoing(page);
    this.filterTableFinished(page);
  }

  public onPageChangeAttention(event: PageEvent) {
    this.pageSizeAttention = event.pageSize;
    this.filterTableAttention(event.pageIndex);
  }

  public getDisputesCsv(separator: string) {
    this.disputeService
      .getDisputesCsv(
        formatDate(this.startDate || ''),
        formatDate(this.endDate || ''),
        this.searchParam,
        separator
      )
      .subscribe((csvData: string) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        let realm = environment.theme.split('-')[1];
        saveAs(blob, `${realm}-disputes.csv`);
      });
  }

  public filterTableAttention(page: number = 0) {
    this.isLoading = true;
    this.page = page;
    this.dataSourceAttention = [];

    this.disputeService
      .getReports(
        formatDate(this.startDate || ''),
        formatDate(this.endDate || ''),
        this.searchParam,
        ['CREATED', 'EVIDENCE_PENDING'],
        this.page,
        this.pageSizeAttention
      )
      .subscribe(({ content, totalElements }) => {
        this.isLoading = false;
        this.dataSourceAttention = content;
        this.selector.totalElements = totalElements;
      });
  }

  public onPageChangeOnGoing(event: PageEvent) {
    this.pageSizeOnGoing = event.pageSize;
    this.filterTableOnGoing(event.pageIndex);
  }

  public filterTableOnGoing(page: number = 0) {
    this.isLoading = true;
    this.page = page;
    this.dataSourceOnGoing = [];

    this.disputeService
      .getReports(
        formatDate(this.startDate || ''),
        formatDate(this.endDate || ''),
        this.searchParam,
        ['EVIDENCE_SUBMITTED', 'EVIDENCE_RECEIVED'],
        this.page,
        this.pageSizeOnGoing
      )
      .subscribe(({ content, totalElements }) => {
        this.isLoading = false;
        this.dataSourceOnGoing = content;
        this.selector.totalElements = totalElements;
      });
  }

  public onPageChangeFinished(event: PageEvent) {
    this.pageSizeFinished = event.pageSize;
    this.filterTableOnGoing(event.pageIndex);
  }

  public filterTableFinished(page: number = 0) {
    this.isLoading = true;
    this.page = page;
    this.dataSourceFinished = [];

    this.disputeService
      .getReports(
        formatDate(this.startDate || ''),
        formatDate(this.endDate || ''),
        this.searchParam,
        ['RECOVERED', 'DISPUTE_FINAL', 'REFUNDED'],
        this.page,
        this.pageSizeOnGoing
      )
      .subscribe(({ content, totalElements }) => {
        this.isLoading = false;
        this.dataSourceFinished = content;
        this.selector.totalElements = totalElements;
      });
  }
}
