import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DisputesService,
  TransactionEvidence,
} from '../../../services/disputes/disputes.service';

import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dispute-details',
  templateUrl: './dispute-details.component.html',
  styleUrls: ['./dispute-details.component.scss'],
})
export class DisputeDetailsComponent {
  @Input() disputeId = '';
  public merchantName = [];
  public loading: boolean = false;
  public filterParams = {
    page: null,
    selectedMerchant: null,
    paymentStartDateValue: null,
    paymentEndDateValue: null,
  };
  public detailsInfoList: any = [
    { label: 'Dispute ID', value: 'id' },
    { label: 'Dispute Status', value: 'status' },
    { label: 'Expiration Date', value: 'expirationDate' },
    { label: 'Dispute Reason', value: 'reason' },
  ];

  public buyerInfoList: any = [
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Phone Number', value: 'phone' },
    { label: 'Card Number', value: 'cardNumber' },
    { label: 'Tax ID', value: 'taxId' },
  ];

  public transactionDetailsInfoList: any = [
    { label: 'Transaction ID', value: 'transactionId' },
    { label: 'Transaction Timestamp', value: 'date' },
    { label: 'Reference ID', value: 'referenceId' },
    { label: 'Transaction Country', value: 'country' },
    { label: 'Amount', value: 'amount' },
    { label: 'IP Address', value: 'ipAddress' },
    { label: 'IP Address Country', value: 'ipAddressCountry' },
  ];

  public displayedColumns: string[] = ['proof', 'action'];

  public dataSource: any = { transaction: {}, buyer: {} };
  public contestModal: boolean = false;
  public refundModal: boolean = false;
  public submittedModal: boolean = false;
  public selectedFile: File | null = null;

  public transactionEvidence: TransactionEvidence = {
    ip_address: '',
    email: '',
    phone_number: '',
    purchase_date: '',
    delivery_date: '',
    description: '',
  };

  filesDataSource = new MatTableDataSource<{ file_name: string }>([]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private disputeService: DisputesService,
    private http: HttpClient
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.disputeId = params['id'];
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.filterParams.page = this.activatedRoute.snapshot.queryParams['page'];
      this.filterParams.paymentEndDateValue =
        this.activatedRoute.snapshot.queryParams['endDateValue'];
      this.filterParams.paymentStartDateValue =
        this.activatedRoute.snapshot.queryParams['startDateValue'];
    });

    this.getDisputesInfo();
  }

  public getDisputesInfo() {
    if (this.disputeId) {
      this.disputeService
        .getDisputeDetail(this.disputeId)
        .subscribe((response) => {
          this.dataSource = response;
          this.loading = false;
          if (
            this.dataSource.status === 'DISPUTE_FINAL' ||
            this.dataSource.status === 'EVIDENCE_PENDING' ||
            this.dataSource.status === 'EVIDENCE_RECEIVED'
          ) {
            this.disputeService
              .getDisputeEvidences(this.disputeId)
              .subscribe((response) => {
                this.filesDataSource = response.files;
                this.transactionEvidence.ip_address = response.ip_address;
                this.transactionEvidence.description = response.description;
                this.transactionEvidence.phone_number = response.phone_number;
                this.transactionEvidence.delivery_date = response.delivery_date;
                this.transactionEvidence.purchase_date = response.purchase_date;
                this.transactionEvidence.email = response.email;
              });
          }
        });
    }
  }

  public toggleRefundModal() {
    this.refundModal = !this.refundModal;
  }

  public toggleContestModal() {
    this.contestModal = !this.contestModal;
  }

  public toggleSubmittedModal() {
    this.submittedModal = !this.submittedModal;
  }

  public refundTransaction() {
    this.disputeService.patchRefundDispute(this.disputeId).subscribe(() => {
      this.getDisputesInfo()
    });
    this.refundModal = false;
  }

  public recoverTransaction() {
    this.disputeService.patchRecoverDispute(this.disputeId).subscribe(() => {
      this.getDisputesInfo()
    });
  }

  public disputeFinalTransaction() {
    this.disputeService.patchFinalDispute(this.disputeId).subscribe(() => {
      this.getDisputesInfo()
    });
  }

  public downloadFile(file: any) {
    const url = `${environment.apiURL}/dispute/file/${file.id}`;

    this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(response);
      a.href = objectUrl;
      a.download = file.file_name;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  public saveAndContinue() {
    this.disputeService
      .postSaveEvidenceInformation(this.disputeId, this.transactionEvidence)
      .subscribe();
  }

  public submitContest() {
    this.disputeService.patchContest(this.disputeId).subscribe(() => {
      this.getDisputesInfo()
      this.contestModal = false;
    });
  }

  public submitEvidence() {
    this.disputeService
      .postEvidenceInformation(this.disputeId, this.transactionEvidence)
      .subscribe(() => {
        this.getDisputesInfo()
        this.submittedModal = false;
      });
  }

  public deleteFile(file: any){
    this.disputeService.deleteEvidence(this.disputeId, file.id).subscribe(()=>{
      alert('Evidence deleted!')
      this.getDisputesInfo();
    });
  }

  public uploadEvidence() {
    if (this.selectedFile) {
      this.disputeService
        .postEvidenceFiles(this.disputeId, this.selectedFile)
        .subscribe(
          (response) => {
            this.getDisputesInfo()
          },
          (error) => {
            console.error('Upload failed', error);
          }
        );
    }
  }

  public onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {

      this.uploadEvidence();
    }
  }

}
