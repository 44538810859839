import { Injectable, OnInit } from '@angular/core';
import { MenuItem } from '../../components/side-bar/side-bar.component';
import { PayoutsService } from '../payouts/payouts.service';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  public isMinimized = false;

  private selectedItems: (MenuItem | null)[] = [null, null, null];

  constructor() {}

  public setItem(level: number, item: MenuItem | null): void {
    if (level >= 0 && level < this.selectedItems.length) {
      this.selectedItems[level] = item;
    }
  }

  public getItem(level: number): MenuItem | null {
    if (level >= 0 && level < this.selectedItems.length) {
      return this.selectedItems[level];
    }
    return null;
  }

  public saveStateToLocalStorage(): void {
    const state = {
      selectedItems: this.selectedItems,
    };
    localStorage.setItem('sidebarState', JSON.stringify(state));
  }

  public loadStateFromLocalStorage(): any {
    const storedState = localStorage.getItem('sidebarState');
    if (storedState) {
      return JSON.parse(storedState);
    }
    return {
      selectedItems: [null, null, null],
    };
  }
}
