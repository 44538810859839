import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FirewallRulesService } from '../../../services/firewall-rules/firewall-rules.service';
import { finalize, forkJoin } from 'rxjs';
import { BlockMethods, PaymentMethods } from '../../../utils/enum';
import {
  Contract,
  ProjectsService,
} from '../../../services/projects/projects.service';

@Component({
  selector: 'app-block-modal',
  templateUrl: './block-modal.component.html',
  styleUrls: ['./block-modal.component.scss'],
})
export class BlockModalComponent implements OnInit {
  @Output() closeModalRequest = new EventEmitter<void>();
  @Input() blockData = {};

  public options: { label: string; value: string }[] = [];
  public projectsList: Contract[] = [];
  public selectedProjects: any = '';
  public creditCardSelected = false;
  public pixSelected = false;
  public boletoSelected = false;
  public selectedAllContracts: boolean = false;
  public rule = '';
  public ruleType = '';
  public ruleDescription = '';
  public paymentMethodsList: string[] = [
    'BITCOIN',
    'BOLETO',
    'CREDITCARD',
    'DEBITCARD',
    'NOT_DEFINED',
    'OXXO',
    'PIX',
    'RIPPLE',
    'SPEI',
    'TEDDOC',
  ];
  public selectedPaymentMethods: { [key: string]: boolean } = {};
  public PaymentMethodsEnum = PaymentMethods;

  constructor(
    private firewallRulesService: FirewallRulesService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.getBlockMethods();
    this.projectsService.getProjects().subscribe(({ contracts }) => {
      this.projectsList = contracts;
    });
  }

  ngOnChange(change: SimpleChanges) {
    if (change['blockData'] && change['blockData'].currentValue) {
      const newValue = change['blockData'].currentValue;
    }
  }

  public close() {
    this.closeModalRequest.emit();
  }

  public createNewBlock() {
    const paymentMethodFlags = {
      PIX: this.pixSelected,
      BOLETO: this.boletoSelected,
      CREDITCARD: this.creditCardSelected,
    };

    const paymentMethods = Object.keys(paymentMethodFlags).filter(
      (method) => paymentMethodFlags[method as keyof typeof paymentMethodFlags]
    );

    let contractId: string | Array<string> = '';

    if (!this.selectedAllContracts) {
      contractId = this.selectedProjects;
    }

    const rules = {
      contract_id: contractId,
      rule_type: this.ruleType,
      rule: this.rule,
      description: this.ruleDescription,
      payment_methods: paymentMethods,
    };

    this.firewallRulesService
      .postFirewallRules(rules)
      .pipe(
        finalize(() => {
          this.close();
          location.reload();
        })
      )
      .subscribe({
        next: () => {
          location.reload();
        },
        error: (e) => {
          alert('Failed to block');
        },
      });
  }

  public getFirstHalfOfPaymentMethods() {
    const halfIndex = Math.ceil(this.paymentMethodsList.length / 2);
    return this.paymentMethodsList.slice(0, halfIndex);
  }

  public getSecondHalfOfPaymentMethods() {
    const halfIndex = Math.ceil(this.paymentMethodsList.length / 2);
    return this.paymentMethodsList.slice(halfIndex);
  }

  public getPaymentMethodName(method: string): string {
    return PaymentMethods[method as keyof typeof PaymentMethods];
  }

  private getBlockMethods() {
    this.firewallRulesService.getBlockMethods().subscribe((response) => {
      response.forEach((element: string) => {
        this.options.push({
          value: element,
          label: BlockMethods[element as keyof typeof BlockMethods],
        });
      });
    });
  }
}
