import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import {
  FirewallRule,
  FirewallRulesService,
} from '../../../services/firewall-rules/firewall-rules.service';

@Component({
  selector: 'app-firewall-rules',
  templateUrl: './firewall-rules.component.html',
  styleUrls: ['./firewall-rules.component.scss'],
})
export class FirewallRulesComponent {
  constructor(private firewallRulesService: FirewallRulesService) {}

  public rows: any = [];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public search: string | undefined = undefined;
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public openModalValue: boolean = false;
  public openUnblockModalValue: boolean = false;
  public openReblockModalValue: boolean = false;
  public merchantId: string = '';
  public selectedBlockRule: FirewallRule = {
    contract_id: '',
    rule_type: '',
    rule: '',
    description: '',
    payment_methods: [],
  };
  public displayedColumns: string[] = [
    'type',
    'rule_type',
    'rule',
    'description',
    'created_at',
    'blocked_by',
    'unblock',
  ];
  public dataSource = [];
  public isLoading: boolean = false;
  private cacheAPI = sessionStorage;

  ngOnInit(): void {
    this.merchantId = JSON.parse(
      this.cacheAPI.getItem('user') || '[]'
    ).merchant_id;
    this.filterTable();
  }

  public filterTable(page: number = 0) {
    this.isLoading = true;
    this.page = page;

    this.firewallRulesService
      .getFirewallRules(this.page, this.search)
      .subscribe(({ content }) => {
        this.dataSource = content;
        this.selector = content.length;
        this.isLoading = false;
      });
  }
  public onPageChange(event: PageEvent) {
    this.filterTable(event.pageIndex);
  }

  public openModal(element: any = {}) {
    this.selectedBlockRule = element;
    this.openModalValue = true;
  }

  public openUnblockModal(element: FirewallRule) {
    this.selectedBlockRule = element;
    this.openUnblockModalValue = true;
  }

  public openReblockModal(element: FirewallRule) {
    this.selectedBlockRule = element;
    this.openReblockModalValue = true;
  }

  public handleCloseModal() {
    this.openModalValue = false;
    this.openUnblockModalValue = false;
    this.openReblockModalValue = false;
  }
}
