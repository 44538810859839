import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export type TransactionEvidence = {
  ip_address: string;
  phone_number: string;
  purchase_date: string;
  delivery_date: string;
  description: string;
  email: string;
};

@Injectable({
  providedIn: 'root',
})
export class DisputesService {
  private baseUrl = `${environment.apiURL}/dispute`;
  private reportUrl = `${environment.apiURL}/report`;
  private cacheAPI = sessionStorage;

  constructor(private http: HttpClient) {}

  public getStatus() {}

  public postCreateDispute(
    payment_id: number,
    dispute_date: string,
    expiration_date: string,
    reason: string
  ): Observable<any> {
    return this.http.post(this.baseUrl, {
      payment_id: payment_id,
      dispute_date: dispute_date,
      expiration_date: expiration_date,
      reason: reason,
    });
  }

  public getDisputeEvidences(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}/evidence`);
  }

  public patchRefundDispute(id: string) {
    return this.http.patch(`${this.baseUrl}/${id}/refund`, {});
  }

  public patchRecoverDispute(id: string) {
    return this.http.patch(`${this.baseUrl}/${id}/evidence/recovered`, {});
  }

  public patchFinalDispute(id: string) {
    return this.http.patch(`${this.baseUrl}/${id}/evidence/final`, {});
  }

  public patchEvidenceInformation(id: string) {
    return this.http.patch(`${this.baseUrl}/${id}/evidence/submitted`, {});
  }

  public postSaveEvidenceInformation(
    id: string,
    transactionEvidence: TransactionEvidence
  ) {
    return this.http.post(
      `${this.baseUrl}/${id}/evidence/save`,
      transactionEvidence
    );
  }

  public postEvidenceInformation(
    id: string,
    transactionEvidence: TransactionEvidence
  ) {
    return this.http.post(
      `${this.baseUrl}/${id}/evidence`,
      transactionEvidence
    );
  }

  public deleteEvidence(disputeId: string, fileId: string) {
    return this.http.delete(
      `${this.baseUrl}/${disputeId}/evidence/file/${fileId}`
    );
  }

  public postEvidenceFiles(id: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.baseUrl}/${id}/evidence/file`, formData);
  }

  public getDisputesCsv(
    startDate: string | undefined,
    endDate: string | undefined,
    search: string = '',
    separatorType: string = 'comma'
  ): Observable<any> {
    const filter = {
      endDate: endDate,
      startDate: startDate,
      search: search,
      separatorType: separatorType,
    };

    const url = `${this.reportUrl}/disputes/csv`;

    let queryParams = new HttpParams();

    queryParams = filter.search
      ? queryParams.append('search', filter.search)
      : queryParams;

    queryParams = filter.startDate
      ? queryParams.append('startDate', filter.startDate)
      : queryParams;

    queryParams = filter.endDate
      ? queryParams.append('endDate', filter.endDate)
      : queryParams;

    queryParams = queryParams.append('page', 0);
    queryParams = queryParams.append('size', 9999999);
    queryParams = queryParams.append('separatorType', separatorType);
    return this.http.get(url, {
      params: queryParams,
      responseType: 'text',
    });
  }

  public getReports(
    startDate: string | undefined,
    endDate: string | undefined,
    search: string = '',
    status: any,
    page: number,
    pageSize: number
  ): Observable<any> {
    const filter = {
      endDate: endDate,
      startDate: startDate,
      search: search,
      status: status,
      pageSize: pageSize,
      page: page,
    };

    let queryParams = new HttpParams();
    let merchantId = JSON.parse(
      this.cacheAPI.getItem('user') || '[]'
    ).merchant_id;

    queryParams = merchantId
      ? queryParams.append('merchantId', merchantId)
      : queryParams;

    queryParams = filter.page
      ? queryParams.append('page', filter.page)
      : queryParams;

    queryParams = filter.search
      ? queryParams.append('search', filter.search)
      : queryParams;

    queryParams = filter.status
      ? queryParams.append('status', filter.status)
      : queryParams;

    queryParams = filter.pageSize
      ? queryParams.append('size', filter.pageSize)
      : queryParams;

    queryParams = filter.startDate
      ? queryParams.append('startDate', filter.startDate)
      : queryParams;

    queryParams = filter.endDate
      ? queryParams.append('endDate', filter.endDate)
      : queryParams;

    const url = `${this.reportUrl}/disputes`;

    return this.http.get<any>(url, { params: queryParams });
  }

  public getDisputeDetail(id: string) {
    const url = `${this.reportUrl}/disputes/${id}/details`;
    return this.http.get<any>(url);
  }

  public patchContest(id: string) {
    return this.http.patch(`${this.baseUrl}/${id}/contest`, {});
  }
}
