import { Component } from '@angular/core';
import { MerchantService } from '../../../services/merchant/merchant.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss'],
})
export class ApiKeysComponent {
  constructor(private merchantService: MerchantService) {}

  public rows: any = [];
  public search: string = '';
  public pageSize: number = 10;
  public page: number = 0;
  public selector: any = {};
  public displayedColumns: string[] = ['api_key', 'created_at', 'last_access'];
  public dataSource: any = [];
  public apiLabel: string = '';
  public apiDescription: string = '';
  public apiData = { api_key: '', api_secret: '' };
  public openCreateModal: boolean = false;
  public openSuccessModal: boolean = false;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.getApiKeysInfo();
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.getApiKeysInfo();
  }

  public getApiKeysInfo() {
    this.isLoading = true;
    this.merchantService
      .getApiKeys(this.search, this.page, this.pageSize)
      .subscribe(({ content, totalElements }: any) => {
        this.dataSource = content;
        this.selector = totalElements;
        this.isLoading = false;
      });
  }

  public openModal() {
    this.openCreateModal = true;
  }

  public closeModal(modal: string) {
    if (modal === 'success') {
      this.openSuccessModal = false;
      location.reload();
    } else {
      this.openCreateModal = false;
    }
  }

  public createAPIKey() {
    this.merchantService
      .postApiKeys(this.apiLabel, this.apiDescription)
      .subscribe({
        next: (response: any) => {
          this.apiData = response;
          this.openCreateModal = false;
          this.openSuccessModal = true;
        },
      });
  }

  public copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert('Copied to clipboard!');
  }
}
