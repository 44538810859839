import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FirewallRule,
  FirewallRulesService,
} from '../../../services/firewall-rules/firewall-rules.service';
import { ProjectsService } from '../../../services/projects/projects.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-reblock-modal',
  templateUrl: './reblock-modal.component.html',
  styleUrls: ['./reblock-modal.component.scss'],
})
export class ReblockModalComponent {
  @Output() closeModalRequest = new EventEmitter<void>();
  @Input() blockData: FirewallRule = {
    id: 'a',
    contract_id: '',
    rule_type: '',
    rule: '',
    description: '',
    payment_methods: [],
  };
  public selectedPaymentMethods: { [key: string]: boolean } = {};
  public unblockReason: string = '';

  constructor(
    private firewallRulesService: FirewallRulesService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['blockedContract'] && changes['blockedContract'].currentValue) {
      this.blockData = changes['blockedContract'].currentValue;
    }
  }

  public close() {
    this.closeModalRequest.emit();
  }

  public blockContract() {
    this.firewallRulesService
      .postReblock(this.blockData.id)
      .pipe(
        finalize(() => {
          this.close();
          location.reload();
        })
      )
      .subscribe({
        next: () => {
          location.reload();
        },
        error: (e) => {
          alert('Failed to unblock');
        },
      });
  }
}
