import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth/auth-service.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): true | UrlTree {
    const hasToken = this.authService.getDefaultHeaderToken();
    if (hasToken) {
      return true;
    } else {
      return this.router.parseUrl('/login');
    }
  }
}
