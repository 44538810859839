import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateFilterWithPagination } from '../payments/payments.service';

@Injectable({
  providedIn: 'root',
})
export class IdValidationService {
  private baseUrl = `${environment.apiURL}/identityValidation`;

  constructor(private http: HttpClient) {}

  public getIdValidation(
    start: string = this.getOneMonthAgoDate(),
    end: string = this.getCurrentDate(),
    search: string = '',
    page: number,
    pageSize: number
  ): Observable<any> {
    const filter: DateFilterWithPagination = {
      end: end,
      start: start,
      pageSize: pageSize,
      page: page,
      search: search,
    };
    let queryParams = new HttpParams();
    queryParams = filter.search
      ? queryParams.append('search', filter.search)
      : queryParams;

    queryParams = filter.page
      ? queryParams.append('page', filter.page)
      : queryParams;

    queryParams = filter.pageSize
      ? queryParams.append('pageSize', filter.pageSize)
      : queryParams;

    queryParams = filter.start
      ? queryParams.append('startDate', filter.start)
      : queryParams;

    queryParams = filter.end
      ? queryParams.append('endDate', filter.end)
      : queryParams;

    const url = `${this.baseUrl}`;
    return this.http.get<any>(url, { params: queryParams });
  }

  public getValidDocuments(
    start: string = this.getOneMonthAgoDate(),
    end: string = this.getCurrentDate()
  ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('startDate', start);

    queryParams = queryParams.append('endDate', end);

    const url = `${this.baseUrl}/validDocumentAmount`;
    return this.http.get<any>(url, { params: queryParams });
  }

  public getIdValidationSummary(
    start: string = this.getOneMonthAgoDate(),
    end: string = this.getCurrentDate()
  ): Observable<any> {
    const filter = {
      end: end,
      start: start,
    };
    let queryParams = new HttpParams();

    queryParams = filter.start
      ? queryParams.append('startDate', filter.start)
      : queryParams;

    queryParams = filter.end
      ? queryParams.append('endDate', filter.end)
      : queryParams;

    const url = `${this.baseUrl}/summary`;
    return this.http.get<any>(url, { params: queryParams });
  }

  public getIdValidationStatements(
    start: string = this.getOneMonthAgoDate(),
    end: string = this.getCurrentDate(),
    search: string = '',
    page: number,
    pageSize: number
  ): Observable<any> {
    const filter: DateFilterWithPagination = {
      end: end,
      start: start,
      pageSize: pageSize,
      page: page,
      search: search,
    };
    let queryParams = new HttpParams();
    queryParams = filter.search
      ? queryParams.append('search', filter.search)
      : queryParams;

    queryParams = filter.page
      ? queryParams.append('page', filter.page)
      : queryParams;

    queryParams = filter.pageSize
      ? queryParams.append('pageSize', filter.pageSize)
      : queryParams;

    queryParams = filter.start
      ? queryParams.append('startDate', filter.start)
      : queryParams;

    queryParams = filter.end
      ? queryParams.append('endDate', filter.end)
      : queryParams;

    const url = `${this.baseUrl}/statements`;
    return this.http.get<any>(url, { params: queryParams });
  }

  public getIdValidationDetail(statementId: string) {
    const url = `${this.baseUrl}/statements/${statementId}`;

    return this.http.get<any>(url);
  }

  private getCurrentDate(): string {
    return new Date().toISOString().split('T')[0];
  }

  private getOneMonthAgoDate(): string {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    return oneMonthAgo.toISOString().split('T')[0];
  }
}
