<div class="mt-5">
  <app-fieldset title="Filters">
    <mat-form-field appearance="outline">
      <mat-label>ID/ Reference ID</mat-label>
      <input
        placeholder="Input a reference ID"
        #searchInput
        [(ngModel)]="referenceId"
        matInput
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Payout Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="payoutDate">
        <input
          matStartDate
          placeholder="Payout Start Date"
          [(ngModel)]="payoutStartDateValue"
        />
        <input
          matEndDate
          placeholder="Payout End Date"
          [(ngModel)]="payoutEndDateValue"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="payoutDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #payoutDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="selectedStatus">
        <mat-option *ngFor="let project of statusList" [value]="project">
          {{ project }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Downloads">
    <app-download-csv-button
      (csvFunction)="downloadCSV()"
      buttonText="Transactions"
    ></app-download-csv-button>
    <!-- <button mat-flat-button color="primary">
  <mat-icon class="material-symbols-outlined">download</mat-icon>KYC
</button> -->
  </app-fieldset>

  <ngx-simplebar [options]="{ autoHide: false }" style="overflow-y: hidden">
    <div class="row">
      <app-card [cardTitle]="''" style="margin-right: 16px !important">
        <div>
          <table
            mat-table
            [dataSource]="dataSource"
            class="transactions-table"
            cdk-virtual-scroll
            [loader]="isLoading"
          >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">
                <a (click)="saveFiltersAndNavigate(element.tx_id)">
                  {{ element.tx_id }}</a
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="referenceId">
              <th mat-header-cell *matHeaderCellDef>Reference ID</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.reference_id }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="payoutDate">
              <th mat-header-cell *matHeaderCellDef>Payout Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.payout_date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="asset">
              <th mat-header-cell *matHeaderCellDef>Asset</th>
              <td mat-cell *matCellDef="let element">
                {{ element.asset }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element">
                {{ element.amount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="beneficiary">
              <th mat-header-cell *matHeaderCellDef>Beneficiary</th>
              <td mat-cell *matCellDef="let element">
                {{ element.beneficiary }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </app-card>
    </div>
  </ngx-simplebar>
  <div style="max-width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<app-modal
  title="Settings"
  [openModal]="CSVSeparatorModal"
  (closeModalRequest)="toggleCSVModal()"
>
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>CVS Separator</mat-label>
      <mat-select
        [(ngModel)]="selectedSeparator"
        placeholder="Select a CVS Separator"
      >
        <mat-option
          *ngFor="let separator of separatorOptions"
          [value]="separator.value"
        >
          {{ separator.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <button mat-stroked-button (click)="toggleCSVModal()">Cancel</button>
    <button mat-flat-button color="primary" (click)="downloadCSV()">
      Save
    </button>
  </div>
</app-modal>
