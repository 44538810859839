import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../../services/payments/payments.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-transaction-reports',
  templateUrl: './transaction-reports.component.html',
  styleUrls: ['./transaction-reports.component.scss'],
})
export class TransactionReportsComponent implements OnInit {
  constructor(private paymentsService: PaymentsService) {}

  public rows: any = [];
  public startDate: string | undefined = undefined;
  public endDate: string | undefined = undefined;
  public startDateValue: string | undefined = undefined;
  public endDateValue: string | undefined = undefined;
  public search: string | undefined = undefined;
  public selector: any = {};
  public pageSize: number = 10;
  public page: number = 0;
  public disabledWhileRequest: boolean = false;
  public isLoading: boolean = false;
  public filterParamsCsv = {};
  public selectedStatus: string = '';

  public displayedColumns: string[] = [
    'paymentId',
    'paymentStatus',
    'reference',
    'contractId',
    'createdAt',
    'confirmationDate',
    'ipAddrFrom',
    'paymentMethod',
    'originalAmount',
    'originalAssetCurrencyCode',
    'convertedRate',
    'amount',
    'amountReceived',
    'currencyCode',
    'installments',
    'merchantFeeTotal',
    'merchantAmount',
    'personGatewayId',
    'personEmail',
    'payoutStatus',
    'paymentCycleId',
  ];
  public dataSource = [];
  public filterParams = {};
  public statusList: string[] = [];

  ngOnInit(): void {
    this.filterTable();

    this.paymentsService.getPaymentStatuses().subscribe(({ content }) => {
      this.statusList = content;
    })
  }

  public onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.filterTable(event.pageIndex);
  }

  public filterTable(page: number = 0) {
    this.isLoading = true;

    this.filterParamsCsv = {
      reportURL: 'transactionsReportCsv',
      start: this.startDateValue,
      end: this.endDateValue,
      search: this.search,
      status: this.selectedStatus,
      pageSize: this.pageSize,
      page: page,
      reportName: 'TransactionsReportFile',
    };

    this.dataSource = [];
    this.page = page;
    this.paymentsService
      .postReportDateFilter(
        'transactionsReport',
        this.startDateValue,
        this.endDateValue,
        this.search,
        this.pageSize,
        page,
        this.selectedStatus,
      )
      .subscribe(({ response, selection }) => {
        this.dataSource = response;
        this.selector = selection;
        this.isLoading = false;
      });
  }
}
