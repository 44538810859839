<div class="mt-5">
  <div class="row">
    <app-card cardTitle="Business Information" [hasDivider]="true">
      <div class="row">
        <b class="flex-one">Corporate: </b>
        {{ businessInfo.corporateName || "-" }}
      </div>

      <div class="row">
        <b class="flex-one">ID: </b>
        {{ businessInfo.id }}
      </div>

      <div class="row">
        <b class="flex-one">Taxpayer ID: </b>
        {{ businessInfo?.person?.taxId || "-" }}
      </div>
      <div class="row">
        <b class="flex-one">Telephone Number: </b>
        {{ businessInfo.phoneNumber || "-" }}
      </div>
      <div class="row">
        <b class="flex-one">Primary Activity: </b>
        {{ businessInfo?.primaryActivity || "-" }}
      </div>
      <div class="row">
        <b class="flex-one">Opening Date: </b>
        {{ businessInfo.openingDate | date }}
      </div>
      <div class="row">
        <b class="flex-one">Address: </b>
        {{ businessInfo?.person?.mainAddress || "-" }}
      </div>
      <div class="row">
        <b class="flex-one">City: </b>
        {{ businessInfo?.person?.cityAddress || "-" }}
      </div>
      <div class="row">
        <b class="flex-one">Country: </b>
        {{ businessInfo?.person?.countryAddress || "-" }}
      </div>
      <div class="row">
        <b class="flex-one">Notification URL: </b>
        {{ businessInfo.notificationUrl || "-" }}
      </div>
    </app-card>
    <div class="column">
      <app-card
        style="width: 80%"
        cardTitle="Supporting Documents"
        [hasDivider]="true"
      >
        <div class="row">
          <b class="flex-one">Incorporation Papers </b>
          <img
            style="max-width: 22px"
            [src]="
              true
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>

        <div class="row">
          <b class="flex-one">Board Ellection </b>
          <img
            style="max-width: 22px"
            [src]="
              false
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>

        <div class="row">
          <b class="flex-one">Proof of Address </b>
          <img
            style="max-width: 22px"
            [src]="
              false
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>

        <div class="row">
          <b class="flex-one">Power of Attorney </b>
          <img
            style="max-width: 22px"
            [src]="
              false
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>
      </app-card>
      <app-card
        style="width: 80%"
        cardTitle="Account Manager"
        [hasDivider]="true"
      >
        <div class="row">
          <b>Name </b> {{ businessInfo?.person?.name || "-" }}
        </div>

        <div class="row">
          <b>Email </b> {{ businessInfo?.person?.email || "-" }}
        </div>

        <div class="row">
          <b>Role </b> {{ businessInfo?.roles?.description }}
        </div>
        <div class="row">
          <b>Status </b>
          <img
            style="max-width: 22px"
            [src]="
              businessInfo?.roles?.active
                ? '../../../../assets/common/icons/enabled.svg'
                : '../../../../assets/common/icons/disabled.svg'
            "
            alt=""
          />
        </div>
      </app-card>
    </div>
  </div>
</div>
