import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filters: Map<string, any> = new Map();

  setFilters(key: string, filters: any): void {
    this.filters.set(key, filters);
  }

  getFilters(key: string): any {
    return this.filters.get(key) || {};
  }

  resetFilters(key: string): void {
    this.filters.delete(key);
  }
}
