import { Component, OnInit } from '@angular/core';
import { MerchantService } from '../../../services/merchant/merchant.service';

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.scss'],
})
export class BusinessInfoComponent implements OnInit {
  public businessInfo: any = {};

  constructor(private merchantService: MerchantService) {}

  ngOnInit(): void {
    this.merchantService.getMerchant().subscribe((response) => {
      this.businessInfo = response;
    });
  }
}
