<div [class]="theme">
  <div id="sidebar">
    <ngx-simplebar>
      <div class="logo-container">
        <img
          [width]="theme === 'skin-epag' ? 153 : 204"
          height="54px"
          [src]="basePath + minimizedPath"
          alt="Logo"
        />
        <button class="arrow-btn" (click)="toggleSidebar()">
          <img [src]="basePath + 'icons/arrow-btn.svg'" alt="Arrow Icon" />
        </button>
      </div>
      <hr class="divider" />
      <ul>
        <ng-container *ngFor="let menuItem of menuItems">
          <li
            *ngIf="menuItem.isVisible"
            [class.selected]="selectedPath[0] === menuItem.label"
            (click)="selectMenuItem($event, menuItem)"
          >
            <a
              class="menu-item"
              *ngIf="!menuItem.subMenu; else subMenuBlock"
              [routerLink]="menuItem.link"
              ><img
                class="mr-2"
                [src]="'./assets/common/icons/' + menuItem.label + '-icon.svg'"
              />
              <div class="menu-item-text">
                {{ menuItem.label }}
              </div>
            </a>

            <ng-template #subMenuBlock>
              <div (click)="toggleMenu($event, menuItem)">
                <a class="menu-item"
                  ><img
                    class="mr-2"
                    [src]="
                      './assets/common/icons/' + menuItem.label + '-icon.svg'
                    "
                  />
                  <div class="menu-item-text">
                    {{ menuItem.label }}
                  </div>
                  <img
                    class="single-arrow-icon"
                    src="./assets/common/icons/singular-arrow-icon.svg"
                  />
                </a>
              </div>
              <ul
                *ngIf="activeSubMenu === menuItem"
                [style.maxHeight]="
                  activeSubMenu === menuItem
                    ? submenuMaxHeight + nestedMenuMaxHeight + 'px'
                    : '0px'
                "
                [ngClass]="{ 'submenu-open': activeSubMenu === menuItem }"
                class="submenu-container"
              >
                <hr />
                <div>
                  <ng-container *ngFor="let subItem of menuItem.subMenu">
                    <li *ngIf="subItem.isVisible">
                      <a
                        *ngIf="!subItem.subMenu; else nestedSubMenuBlock"
                        [class.selected]="selectedPath[1] === subItem.label"
                        (click)="selectSubMenuItem($event, menuItem, subItem)"
                        [routerLink]="subItem.link"
                      >
                        <div class="menu-item-text">
                          {{ subItem.label }}
                        </div>
                      </a>

                      <ng-template #nestedSubMenuBlock>
                        <div
                          (click)="
                            toggleMenu($event, subItem, true);
                            selectSubMenuItem($event, menuItem, subItem)
                          "
                          class="nested-submenu-container"
                        >
                          <a
                            class="menu-item-text"
                            [class.selected]="selectedPath[1] === subItem.label"
                          >
                            {{ subItem.label }}
                          </a>
                          <div
                            class="nested-submenu-row"
                            [style.margin-top]="
                              activeNestedSubMenu === subItem ? '16px' : '0px'
                            "
                          >
                            <hr *ngIf="activeNestedSubMenu === subItem" />
                            <ul
                              [ngClass]="{
                                'nestedmenu-open':
                                  activeNestedSubMenu === subItem
                              }"
                              [style.maxHeight]="
                                activeNestedSubMenu === subItem
                                  ? nestedMenuMaxHeight + 'px'
                                  : '0px'
                              "
                            >
                              <li *ngFor="let nestedSubItem of subItem.subMenu">
                                <a
                                  [class.selected]="
                                    selectedPath[2] === nestedSubItem.label
                                  "
                                  (click)="
                                    selectNestedSubMenuItem(
                                      $event,
                                      menuItem,
                                      subItem,
                                      nestedSubItem
                                    )
                                  "
                                  [routerLink]="nestedSubItem.link"
                                >
                                  <div class="menu-item-text">
                                    {{ nestedSubItem.label }}
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                  </ng-container>
                </div>
              </ul>
            </ng-template>
          </li>
        </ng-container>
      </ul>
    </ngx-simplebar>
    <div id="profile-menu" class="row row-no-margin">
      <hr class="divider" />

      <div class="row" style="margin-top: 0">
        <img
          class="image-circular mr-3"
          src="../../../assets/common/icons/avatar.svg"
        />
        <div
          class="white--text column column-no-margin"
          style="align-items: start"
        >
          <b style="margin-left: 0">
            {{ userData.name }}
          </b>
          {{ userData.username }}
        </div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          style="width: 5px !important; margin-left: -40px !important"
        >
          <mat-icon style="color: #fff">arrow_drop_down</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openCurrencyModal()">
    <mat-icon>paid</mat-icon>Change Currency
  </button>
  <button mat-menu-item (click)="openPersonalInfoModal()">
    <mat-icon>badge</mat-icon>Personal Info
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>Logout
  </button>
</mat-menu>

<app-modal
  [openModal]="openPersonalInfo"
  (closeModalRequest)="handleCloseModal()"
  [minWidth]="'500px'"
>
  <app-card [cardTitle]="'Personal Info'" [hasDivider]="true">
    <app-profile (closeModalRequest)="handleCloseModal()"></app-profile>
  </app-card>
</app-modal>

<app-modal
  [openModal]="openCurrency"
  (closeModalRequest)="handleCloseModal()"
  [minWidth]="'500px'"
  title="Change Currency"
>
  <div class="ml-5 mr-5">
    <app-currency-modal
      (closeModalRequest)="handleCloseModal()"
    ></app-currency-modal>
  </div>
</app-modal>
