<div>
  <div class="row align-right">
    <button class="white-btn" [routerLink]="['/payments/transaction-report']">
      Back
    </button>
  </div>
  <div class="row align-right">
    <button mat-flat-button color="primary" (click)="exportLog()">
      Export transaction log
    </button>
    <button class="block-btn" (click)="redirectToRefund()">Refund</button>
  </div>
  <div class="row mt-5" [loader]="loading">
    <div class="column">
      <app-card cardTitle="General Info" style="width: 90%" [hasDivider]="true">
        <div class="row mt-3 ml-1 mb-2" *ngFor="let item of generalInfoList">
          <div class="column" style="align-items: start; width: 200px">
            <b>{{ item.label }}</b>
          </div>
          <div class="column" style="align-items: start">
            {{ transactionInfo.general_info[item.value] || "-" }}
          </div>
        </div>
      </app-card>
      <app-card
        cardTitle="Customer Info"
        style="width: 90%"
        [hasDivider]="true"
      >
        <div class="row mt-3 ml-1 mb-2" *ngFor="let item of customerInfoList">
          <div class="column" style="align-items: start; width: 200px">
            <b>{{ item.label }}</b>
          </div>
          <div class="column" style="align-items: start">
            {{ transactionInfo?.customer_info[item.value] || "-" }}
          </div>
        </div></app-card
      >
    </div>
    <div class="column">
      <app-card cardTitle="Payment Info" style="width: 90%" [hasDivider]="true">
        <div class="row mt-3 ml-1 mb-2" *ngFor="let item of paymentInfoList">
          <div class="column" style="align-items: start; width: 200px">
            <b>{{ item.label }}</b>
          </div>
          <div class="column" style="align-items: start">
            {{ transactionInfo?.payment_info[item.value] || "-" }}
          </div>
        </div>
        <div class="row align-right mt-5">
          {{
            transactionInfo?.payment_info["fx_updated_date"]
              ? "FX updated on " +
                transactionInfo?.payment_info["fx_updated_date"]
              : "FX not updated"
          }}
        </div></app-card
      >
      <app-card cardTitle="Other Info" style="width: 90%" [hasDivider]="true">
        <div class="row mt-3 ml-1 mb-2" *ngFor="let item of otherInfoList">
          <div class="column" style="align-items: start; width: 200px">
            <b>{{ item.label }}</b>
          </div>
          <div class="column" style="align-items: start">
            {{ transactionInfo?.other_info[item.value] || "-" }}
          </div>
        </div></app-card
      >
    </div>
  </div>
  <div class="row mt-5">
    <div class="column">
      <app-card
        cardTitle="Transaction History"
        style="width: 95%"
        [hasDivider]="true"
      >
        <table
          mat-table
          [dataSource]="transactionInfo.transaction_history"
          class="transactions-table mt-5"
        >
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element" style="text-align: center">
              {{ element.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.timestamp | date : "MM-dd-yyy hh:mm:ss" }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </app-card>
    </div>
  </div>
</div>
