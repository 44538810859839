import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-arrow-icon',
  template: `<div [innerHTML]="arrowIcon()"></div>`,
})
export class ArrowIconComponent {
  constructor(private sanitizer: DomSanitizer) {}

  @Input() value: number = 0;
  public rotateDegree: number = 45;
  public arrowColor: string = '#FFAA00';

  arrowIcon(): SafeResourceUrl {
    if (this.value > 0) {
      this.rotateDegree = -45;
      this.arrowColor = '#1B98E0';
    } else if (this.value < 0) {
      this.rotateDegree = 45;
      this.arrowColor = 'red';
    } else {
      this.rotateDegree = 0;
      this.arrowColor = '#FFAA00';
    }

    let rawSvgCode = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path transform="rotate(${this.rotateDegree} 6.5 6.5)"
        d="M10.1365 7.20711L1.15882 7.21974C0.956788 7.21974 0.78624 7.15021 0.647176 7.01115C0.508112 6.87208 0.438748 6.7017 0.439085 6.50001C0.439085 6.29798 0.508617 6.12743 0.647681 5.98837C0.786746 5.8493 0.957124 5.77994 1.15882 5.78027L10.1365 5.7929L6.29797 1.95432C6.15486 1.81122 6.08331 1.64269 6.08331 1.44874C6.08331 1.25479 6.15486 1.0866 6.29797 0.944171C6.44107 0.801066 6.6096 0.729514 6.80355 0.729514C6.9975 0.729514 7.16569 0.801066 7.30812 0.944171L12.3589 5.99493C12.502 6.13804 12.5735 6.30656 12.5735 6.50051C12.5735 6.69446 12.502 6.86265 12.3589 7.00508L7.30812 12.0558C7.16501 12.1989 6.99649 12.2705 6.80254 12.2705C6.60859 12.2705 6.4404 12.1989 6.29797 12.0558C6.15486 11.9127 6.08331 11.7442 6.08331 11.5503C6.08331 11.3563 6.15486 11.1881 6.29797 11.0457L10.1365 7.20711Z"
        fill="${this.arrowColor}" />
</svg>`;
    return this.sanitizer.bypassSecurityTrustHtml(rawSvgCode);
  }
}
