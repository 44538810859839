<div class="row align-right">
  <button
    class="white-btn"
    [routerLink]="['/payments/resolution-center']"
    [queryParams]="{
      page: filterParams.page,
      selectedMerchant: filterParams.selectedMerchant,
      paymentStartDateValue: filterParams.paymentStartDateValue,
      paymentEndDateValue: filterParams.paymentEndDateValue
    }"
  >
    Back
  </button>
</div>
<div
  class="mt-5 row"
  style="align-items: start !important; display: flex"
  [loader]="loading"
>
  <div class="column mr-5">
    <app-card
      style="flex-grow: 1; min-width: 100%"
      cardTitle="Dispute Details"
      [hasDivider]="true"
    >
      <div class="row" *ngFor="let item of detailsInfoList">
        <div class="column ml-4" style="align-items: start">
          <b>{{ item.label }}</b>
        </div>
        <div
          *ngIf="item.label === 'Dispute Status'"
          style="align-items: end"
          class="column"
        >
          <app-dispute-status-card [text]="dataSource[item.value]" />
        </div>
        <div
          *ngIf="item.label !== 'Dispute Status'"
          class="column"
          style="align-items: end"
        >
          {{ dataSource[item.value] || "-" }}
        </div>
      </div>
    </app-card>

    <app-card cardTitle="Buyer Info" [hasDivider]="true">
      <div class="row" *ngFor="let item of buyerInfoList">
        <div class="column ml-4" style="align-items: start">
          <b>{{ item.label }}</b>
        </div>
        <div class="column" style="align-items: end">
          {{ dataSource?.buyer[item.value] || "-" }}
        </div>
      </div>
    </app-card>

    <app-card
      *ngIf="
        this.dataSource.status !== 'CREATED' &&
        this.dataSource.status !== 'REFUNDED'
      "
      cardTitle="Additional Evidence"
      [hasDivider]="true"
    >
      <app-card cardTitle="">
        <table
          mat-table
          [dataSource]="filesDataSource"
          class="transactions-table"
          cdk-virtual-scroll
        >
          <ng-container matColumnDef="proof">
            <th mat-header-cell *matHeaderCellDef>Proof of delivery</th>
            <td mat-cell *matCellDef="let element">
              {{ element.file_name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="downloadFile(element)">
                <mat-icon class="custom-icon">download</mat-icon>
              </button>

              <button
                *ngIf="
                  this.dataSource.status === 'EVIDENCE_PENDING' ||
                  this.dataSource.status === 'EVIDENCE_RECEIVED'
                "
                mat-icon-button
                (click)="deleteFile(element)"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </app-card>
      <div
        class="row mt-5"
        style="justify-content: end; align-items: end; display: flex"
      >
        <input
          type="file"
          #fileInput
          style="display: none"
          (change)="onFileSelected($event)"
        />
        <button mat-flat-button color="primary" (click)="fileInput.click()">
          Upload
        </button>
      </div>
    </app-card>
  </div>

  <div class="column ml-5">
    <app-card cardTitle="Transaction Details" [hasDivider]="true">
      <div class="row" *ngFor="let item of transactionDetailsInfoList">
        <div class="column ml-4" style="align-items: start">
          <b>{{ item.label }}</b>
        </div>
        <div class="column" style="align-items: end">
          {{ dataSource?.transaction[item.value] || "-" }}
        </div>
      </div>
    </app-card>

    <app-card
      *ngIf="
        this.dataSource.status !== 'CREATED' &&
        this.dataSource.status !== 'REFUNDED'
      "
      cardTitle="Transaction Context Evidence"
      [hasDivider]="true"
    >
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>IP Address</mat-label>
          <input
            matInput
            placeholder="Information"
            [(ngModel)]="transactionEvidence.ip_address"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            placeholder="Information"
            [(ngModel)]="transactionEvidence.email"
          />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input
            matInput
            placeholder="Information"
            [(ngModel)]="transactionEvidence.phone_number"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Purchase Timestamp</mat-label>
          <input
            [matDatepicker]="purchaseDate"
            [(ngModel)]="transactionEvidence.purchase_date"
            matInput
            placeholder="yyyy-MM-dd"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="purchaseDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #purchaseDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Delivery Timestamp</mat-label>
          <input
            [matDatepicker]="deliveryDate"
            [(ngModel)]="transactionEvidence.delivery_date"
            matInput
            placeholder="yyyy-MM-dd"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="deliveryDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #deliveryDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Product / Service</mat-label>
          <input
            matInput
            placeholder="Information"
            [(ngModel)]="transactionEvidence.description"
          />
        </mat-form-field>
      </div>
    </app-card>
  </div>
</div>

<div
  class="row mt-5"
  *ngIf="dataSource.status === 'CREATED'"
  style="justify-content: end; align-items: end; display: flex"
>
  <button mat-flat-button color="primary" (click)="toggleContestModal()">
    Provide Evidence
  </button>
  <button mat-flat-button color="primary" (click)="toggleRefundModal()">
    Refund
  </button>
</div>

<div
  class="row"
  style="justify-content: end"
  *ngIf="this.dataSource.status !== 'CREATED'"
>
  <button
    class="disable-btn"
    style="max-width: 154px"
    [routerLink]="['/payments/resolution-center']"
  >
    Cancel
  </button>
  <button
    class="save-btn ml-5"
    style="max-width: 204px"
    (click)="saveAndContinue()"
  >
    Save and Continue later
  </button>

  <button
    *ngIf="dataSource.status === 'EVIDENCE_PENDING'"
    mat-flat-button
    color="primary"
    (click)="toggleSubmittedModal()"
  >
    Send
  </button>
</div>

<app-modal
  [openModal]="contestModal"
  minWidth="200px"
  title="Alert"
  (closeModalRequest)="toggleContestModal()"
>
  <div class="ml-5 mr-5">
    <p>Are you sure you want to provide evidence for this dispute?</p>
  </div>
  <div class="mb-5 mr-5" style="display: flex; justify-content: end">
    <button
      class="block-btn"
      style="width: 46px"
      (click)="toggleContestModal()"
    >
      No
    </button>
    <button class="send-btn ml-5" style="width: 46px" (click)="submitContest()">
      Yes
    </button>
  </div>
</app-modal>

<app-modal
  [openModal]="refundModal"
  minWidth="200px"
  title="Alert"
  (closeModalRequest)="toggleRefundModal()"
>
  <div class="ml-5 mr-5">
    <p>Are you sure you want to refund this dispute without evidences?</p>
  </div>
  <div class="mb-5 mr-5" style="display: flex; justify-content: end">
    <button class="block-btn" style="width: 46px" (click)="toggleRefundModal()">
      No
    </button>
    <button
      class="send-btn ml-5"
      style="width: 46px"
      (click)="refundTransaction()"
    >
      Yes
    </button>
  </div>
</app-modal>

<app-modal
  [openModal]="submittedModal"
  minWidth="200px"
  title="Alert"
  (closeModalRequest)="toggleSubmittedModal()"
>
  <div class="ml-5 mr-5">
    <p>Are you sure you want to provide only these evidences?</p>
  </div>
  <div class="mb-5 mr-5" style="display: flex; justify-content: end">
    <button
      class="block-btn"
      style="width: 46px"
      (click)="toggleSubmittedModal()"
    >
      No
    </button>
    <button
      class="send-btn ml-5"
      style="width: 46px"
      (click)="submitEvidence()"
    >
      Yes
    </button>
  </div>
</app-modal>
