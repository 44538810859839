<div class="mt-5">
  <div class="row" style="max-width: 50%">
    <mat-form-field appearance="outline">
      <mat-label>From:</mat-label>
      <input
        [matDatepicker]="startDate"
        class="transaction-input"
        matInput
        placeholder="MM-dd-yyyy"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>To:</mat-label>
      <input [matDatepicker]="endDate" matInput placeholder="MM-dd-yyyy" />
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="filterTable()">
      Submit
    </button>
  </div>

  <div class="row align-right">
    <div>
      <mat-form-field class="letpay-input">
        <mat-label>Search:</mat-label>
        <input
          (change)="filterTable()"
          class="transaction-input"
          matInput
          [(ngModel)]="search"
        />
      </mat-form-field>
      <button
        style="min-width: 64px; min-height: 42px"
        mat-flat-button
        color="primary"
      >
        <img src="../../../../assets/common/copy-icon.svg" alt="copy" />
      </button>
      <button
        style="min-width: 64px; min-height: 42px"
        (click)="downloadCSV()"
        mat-flat-button
        color="primary"
      >
        CSV
      </button>
    </div>
  </div>
  <div class="row">
    <app-card [cardTitle]="''">
      <table
        mat-table
        [dataSource]="dataSource"
        class="transactions-table"
        [loader]="isLoading"
      >
        <ng-container matColumnDef="collectionCycle">
          <th mat-header-cell *matHeaderCellDef>Statement ID</th>
          <td mat-cell *matCellDef="let element">
            <div class="last-days-card">{{ element.collectionCycle }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentId">
          <th mat-header-cell *matHeaderCellDef>Contract ID</th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="['/payments/transaction-report/detail']"
              [queryParams]="{ id: element.paymentId }"
            >
              {{ element.paymentId }}</a
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="externalId">
          <th mat-header-cell *matHeaderCellDef>Creation Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.externalId | date : "MM-dd-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="merchantFromId">
          <th mat-header-cell *matHeaderCellDef>Transactions</th>
          <td mat-cell *matCellDef="let element">
            {{ element.merchantFromId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="contractId">
          <th mat-header-cell *matHeaderCellDef>Settlements</th>
          <td mat-cell *matCellDef="let element">
            {{ element.contractId }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
  <div class="row" style="width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector?.totalElements"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
