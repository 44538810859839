import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  public baseUrl: string = `${environment.apiURL}/merchant`;

  constructor(private http: HttpClient) {}

  public getMerchant(): Observable<any> {
    const url = `${this.baseUrl}`;

    return this.http.get(url);
  }

  public getAccounts() {
    const url = `${this.baseUrl}/account`;

    return this.http.get(url);
  }

  public getApiKeys(
    search: string = '',
    page: number = 0,
    pageSize: number = 10
  ) {
    const url = `${environment.apiURL}/access-key`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('search', search);
    queryParams = queryParams.append('page', page);
    queryParams = queryParams.append('pageSize', pageSize);

    return this.http.get(url, { params: queryParams });
  }

  public postApiKeys(label: string, description: string) {
    const url = `${environment.apiURL}/access-key`;
    return this.http.post(url, { label: label, description: description });
  }
}
