import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dispute-status-card',
  templateUrl: './dispute-status-card.component.html',
  styleUrls: ['./dispute-status-card.component.scss'],
})
export class DisputeStatusCardComponent {
  @Input() text: string = '';

  public style: Object = {};

  ngOnChanges(): void {
    if (this.text) {
      this.text = this.text.toLowerCase();

      if (this.text === ('evidence_provided' || 'refunded')) {
        this.style = { background: '#FFAA00' };
      } else if (this.text === ('created' || 'evidence_pending')) {
        this.style = { background: '#019FD1' };
      } else if (this.text === 'evidence_received') {
        this.style = { background: '#FFAA00' };
      } else if (this.text === 'dispute_final') {
        this.style = { background: 'red' };
      } else {
        this.style = { background: '#00D68F' };
      }
    }
  }
}
