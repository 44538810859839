<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="requestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="startDateValue"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="endDateValue"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="requestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #requestDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Search:</mat-label>
      <input [(ngModel)]="search" #searchInput matInput />
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Downloads">
    <app-download-csv-button
      [csvFilter]="filterParamsCsv"
      buttonText="Transactions"
    ></app-download-csv-button>
  </app-fieldset>

  <div class="row">
    <app-card [cardTitle]="''">
      <table
        mat-table
        [dataSource]="dataSource"
        class="transactions-table"
        [loader]="isLoading"
      >
        <ng-container matColumnDef="collectionCycle">
          <th mat-header-cell *matHeaderCellDef>Collection Cycle</th>
          <td mat-cell *matCellDef="let element">
            <div class="last-days-card">{{ element.collectionCycle }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentId">
          <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
          <td mat-cell *matCellDef="let element">
            {{ element.paymentId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef>External ID</th>
          <td mat-cell *matCellDef="let element">
            {{ element.reference }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentMethod">
          <th mat-header-cell *matHeaderCellDef>
            Payment <br />
            Method
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.paymentMethod }}
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            {{ element.amount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="currencyCode">
          <th mat-header-cell *matHeaderCellDef>Currency</th>
          <td mat-cell *matCellDef="let element">
            {{ element.currencyCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Declination<br />Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : "MM-dd-yyyy 'at' hh:mm:ss" || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cancellationDetail">
          <th mat-header-cell *matHeaderCellDef>
            Declination <br />
            Detail
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.declinationsDetail }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.paymentStatus }}
          </td>
        </ng-container>

        <ng-container matColumnDef="errorCode">
          <th mat-header-cell *matHeaderCellDef>Error Code</th>
          <td mat-cell *matCellDef="let element">
            {{ element.errorCode }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
  <div class="row" style="width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
