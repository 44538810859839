<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Request Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="RequestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="requestStartDate"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="requestEndDate"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="RequestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #RequestDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Confirmation Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="confirmationDate">
        <input
          matStartDate
          placeholder="Start Confirmation date"
          [(ngModel)]="confirmationStartDate"
        />
        <input
          matEndDate
          placeholder="End Confirmation date"
          [(ngModel)]="confirmationEndDate"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="confirmationDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #confirmationDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="selectedStatus">
        <mat-option [value]="'ALL'"> ALL </mat-option>
        <mat-option *ngFor="let item of statusList" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Search:</mat-label>
      <input [(ngModel)]="search" #searchInput matInput />
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Downloads">
    <app-download-csv-button
      (csvFunction)="getCSV($event)"
      buttonText="Refunds"
    ></app-download-csv-button>
  </app-fieldset>

  <ngx-simplebar [options]="{ autoHide: false }" style="overflow-y: hidden">
    <div class="row">
      <app-card [cardTitle]="''" style="margin-right: 16px !important">
        <div>
          <table
            mat-table
            [dataSource]="dataSource"
            class="transactions-table"
            cdk-virtual-scroll
            [loader]="isLoading"
          >
            <ng-container matColumnDef="refundID">
              <th mat-header-cell *matHeaderCellDef>Refund ID</th>
              <td
                mat-cell
                *matCellDef="let element"
                [matTooltip]="element.refundID"
              >
                {{ element.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element">
                {{ element.amount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="currency">
              <th mat-header-cell *matHeaderCellDef>Currency</th>
              <td mat-cell *matCellDef="let element">
                {{ element.currency }}
              </td>
            </ng-container>

            <ng-container matColumnDef="refundsStatus">
              <th mat-header-cell *matHeaderCellDef>Refund Status</th>
              <td mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="transactionId">
              <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.transactionId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="refundDate">
              <th mat-header-cell *matHeaderCellDef>Refund Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.confirmationDate | date : "yyyy-MM-dd:HH:mm:ss" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef>Country</th>
              <td mat-cell *matCellDef="let element">
                {{ element.country }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </app-card>
    </div>
  </ngx-simplebar>
  <div style="max-width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector?.totalElements"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
