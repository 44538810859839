import { Component } from '@angular/core';

@Component({
  selector: 'app-tcs',
  templateUrl: './tcs.component.html',
  styleUrls: ['./tcs.component.scss']
})
export class TCsComponent {

}
