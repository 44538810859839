import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth-service.service';
import { CurrencyService } from '../currency/currency.service';
import { CalendarService, FormattedPeriod } from '../calendar/calendar.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface FluctuationResult {
  value: number;
  percentage: number;
  class?: {};
}

export interface FormattedDate {
  start: string;
  end: string;
  page?: number;
  limitLine?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private cacheAPI = new Map();
  private baseUrl = `${environment.apiURL}/report`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private currencyService: CurrencyService,
    private calendarService: CalendarService
  ) {}

  public getFluctuation(last: number, actual: number): FluctuationResult {
    var status, result;

    var percentage = last === 0 ? actual : ((actual - last) * 100) / last;

    result = {
      value: actual,
      percentage: percentage,
    };

    return result;
  }

  public getIndices(period: number, id: string): Observable<any> {
    const cache07 = this.cacheAPI.get('_cache_ModIndices07Sales');
    const cache30 = this.cacheAPI.get('_cache_ModIndices30Sales');

    if (id === '07Sales' && cache07) {
      return of(cache07);
    } else if (id === '30Sales' && cache30) {
      return of(cache30);
    }

    const url = `${this.baseUrl}/reportTotalPaymentByPeriod/`;

    const formattedPeriod = this.calendarService.getPeriod(period);

    return this.http.post<FormattedDate>(url, {
      start: formattedPeriod.start,
      end: formattedPeriod.end,
      page: 0,
    });
  }

  public getBalances(period: number): Observable<any> {
    const formattedPeriod = this.calendarService.getPeriod(period);
    const url = `${this.baseUrl}/merchantBalances`;

    return this.http.post<FormattedDate>(url, {
      start: formattedPeriod.start,
      end: formattedPeriod.end,
      page: 0,
    });
  }

  public getTopPaymentSystems(period: number): Observable<any> {
    const formattedPeriod = this.calendarService.getPeriod(period);
    const url = `${this.baseUrl}/reportTopPaymentMethodMerchantValueByPeriod/`;

    return this.http.post<FormattedDate>(url, {
      start: formattedPeriod.start,
      end: formattedPeriod.end,
      limitLine: 3,
    });
  }

  public getTopContracts(period: number): Observable<any> {
    const formattedPeriod = this.calendarService.getPeriod(period);
    const url = `${this.baseUrl}/reportTopPaymentContractMerchantValueByPeriod/`;

    return this.http.post<FormattedDate>(url, {
      start: formattedPeriod.start,
      end: formattedPeriod.end,
      limitLine: 3,
    });
  }

  public getSettlements() {
    const url = `${this.baseUrl}/paymentsPending/`;

    return this.http.post<any>(url, {});
  }
}
