<button mat-flat-button color="primary" (click)="toggleCSVModal()">
    <mat-icon class="material-symbols-outlined">download</mat-icon> {{ buttonText }}
  </button>
  
  <app-modal
    title="Settings"
    [openModal]="csvSeparatorModal"
    (closeModalRequest)="toggleCSVModal()"
  >
    <div>
      <mat-form-field appearance="outline">
        <mat-label>CVS Separator</mat-label>
        <mat-select [(ngModel)]="selectedSeparator" placeholder="Select a CVS Separator">
          <mat-option *ngFor="let separator of separatorOptions" [value]="separator.value">
            {{ separator.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
    <div class="mt-3 row" [loader]="loading">
      <button mat-stroked-button (click)="toggleCSVModal()">Cancel</button>
      <button mat-flat-button color="primary" (click)="csvFilter ? downloadCsv() : emitCsvDownload()">
        Save
      </button>
    </div>
  </app-modal>
  