import { Component, OnInit } from '@angular/core';
import { IdValidationService } from '../../../services/id-validation/id-validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Fees } from '../../../utils/enum';

export type Invoice = {
  id: string;
  amount: number;
  currencyCode: string;
  createdAt: string;
  dueDate: string;
  status: string;
  transferId: string | null;
};

export type Fee = {
  feeSubtype: keyof typeof Fees;
  amount: number;
  currencyCode: string;
};

export type TerritorySummary = {
  transactionCount: number;
  countryCode: string;
  fees: Fee[];
};

export type Total = {
  currencyCode: string;
  amount: number;
};

export type IdValidationResponse = {
  creationDate: string;
  invoicesGenerated: number;
  invoices: Invoice[];
  territorySummary: TerritorySummary[];
  totals: Total[];
  refresh_token: string;
};

@Component({
  selector: 'app-statements-detail',
  templateUrl: './statements-detail.component.html',
  styleUrls: ['./statements-detail.component.scss'],
})
export class StatementsDetailComponent implements OnInit {
  constructor(
    private idValidationService: IdValidationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.statementId = this.activatedRoute.snapshot.queryParams['statementId'];
    this.idValidationService
      .getIdValidationDetail(this.statementId)
      .subscribe((response: IdValidationResponse) => {
        this.dataSource = response;
        this.territories = response.territorySummary;
        this.invoices = response.invoices;
      });
    this.territories = this.dataSource.territorySummary;
  }

  public dataSource: IdValidationResponse = {
    creationDate: '',
    invoicesGenerated: 0,
    invoices: [],
    territorySummary: [],
    totals: [],
    refresh_token: '',
  };
  public territories: TerritorySummary[] = [
    {
      transactionCount: 0,
      countryCode: '',
      fees: [],
    },
  ];
  public statementId: string = '';
  public invoices: any;
  public feesEnum = Fees;

  ngOnInit(): void {}
}
